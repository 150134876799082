import { createReducer } from 'redux-act';
import {
    authUser,
    setSignupMessage,
    setUserData,
    signOutUser,
    updatePreDraftMessage,
    setRoutes,
    setComponentRoutes,
    userAutoLogin,
    changeNewUserFieldValue,
    setTotalSubUserRecords,
    resetNewSubuserForm,
    toggleAddUserModal,
    changeSubuserCurrentPage,
    setNewSubuserErrorValue,
    setNewSubuserValue,
    changeSubuserPerPage,
    setSubUsers,
} from '../actions';

export interface IUser {
    center_name: string | null;
    createdAt: string | null;
    created_by: number | null;
    email: string | null;
    first_name: string | null;
    id: number;
    last_name: string | null;
    is_tollfree_number: boolean | null;
    twilio_account_sid: string | null;
    pre_draft_message: string | null;
    referral_message: string | null;
    is_verified: boolean | null;
    location: string | null;
    modified_by: number | null;
    phone_number: string | null;
    business_phone_number?: string | null;
    status: string | null;
    token: string | null;
    updatedAt: string | null;
    user_type: string | null;
    website: string | null;
}
export interface ITopBarRoutes {
    navigateTo: string;
    text: string;
}
export interface ITopBarNavigation {
    parentLinkText: string;
    partialLink: string;
    icon: string;
    routes: ITopBarRoutes[];
}

export interface IUserReducer {
    isAuth: boolean;
    user?: IUser;
    subUser: {
        subUsers: IUser[];
        newSubUserErrors?: any;
        newSubUser?: any;
        currentPage: number;
        perPage: number;
        totalRecords: number;
    };
    routes?: ITopBarNavigation[];
    component_routes?: string[];
    isLoading: boolean;
    pre_draft_message?: string | null;
    registerMessage?: {
        isError: boolean;
        message: string;
        errors?: any;
    };
}

const initialState = {
    isAuth: false,
    isLoading: false,
    routes: [],
    component_routes: [],
    subUser: {
        subUsers: [],
        totalRecords: 0,
        currentPage: 1,
        perPage: 10,
    },
    pre_draft_message: undefined,
    registerMessage: undefined,
};

export const user = createReducer<IUserReducer>({}, initialState);
user.on(authUser, (state: IUserReducer) => ({
    ...state,
    isAuth: true,
}));
user.on(
    setSignupMessage,
    (state: IUserReducer, payload: { isError: boolean; message: string; errors?: any } | undefined) => ({
        ...state,
        registerMessage: payload,
    }),
);
user.on(setUserData, (state: IUserReducer, payload: IUser) => ({
    ...state,
    isAuth: true,
    user: payload,
}));

user.on(updatePreDraftMessage, (state: IUserReducer, payload: string) => ({
    ...state,
    pre_draft_message: payload,
}));

user.on(setRoutes, (state: IUserReducer, payload: ITopBarNavigation[]) => ({
    ...state,
    routes: payload,
}));

user.on(setComponentRoutes, (state: IUserReducer, payload: string[]) => ({
    ...state,
    component_routes: payload,
}));

user.on(userAutoLogin, (state: IUserReducer) => ({
    ...state,
    isLoading: true,
}));

user.on(changeNewUserFieldValue, (state: IUserReducer, payload: { key: string; value: string }) => ({
    ...state,
    subUser: {
        ...state.subUser,
        newSubUser: {
            ...state.subUser.newSubUser,
            [payload.key]: payload.value,
        },
        newSubUserErrors: {
            ...state.subUser.newSubUserErrors,
            [payload.key]: '',
        },
    },
}));
user.on(resetNewSubuserForm, (state: IUserReducer) => ({
    ...state,
    subUser: {
        ...state.subUser,
        newSubUser: undefined,
        newSubUserErrors: undefined,
    },
}));
user.on(toggleAddUserModal, (state: IUserReducer, payload: boolean) => ({
    ...state,
    newSubUser: payload ? state.subUser.newSubUser : undefined,
    newSubUserErrors: payload ? state.subUser.newSubUserErrors : undefined,
}));
user.on(setNewSubuserErrorValue, (state: IUserReducer, payload: any) => ({
    ...state,
    subUser: { ...state.subUser, newSubUserErrors: payload },
}));

user.on(setNewSubuserValue, (state: IUserReducer, payload: any) => ({
    ...state,
    subUser: { ...state.subUser, newSubUser: payload },
}));

user.on(setTotalSubUserRecords, (state: IUserReducer, payload: number) => ({
    ...state,
    subUser: { ...state.subUser, totalRecords: payload },
}));
user.on(changeSubuserCurrentPage, (state: IUserReducer, payload: number) => ({
    ...state,
    subUser: { ...state.subUser, currentPage: payload },
}));
user.on(changeSubuserPerPage, (state: IUserReducer, payload: number) => ({
    ...state,
    subUser: { ...state.subUser, perPage: payload },
}));
user.on(setSubUsers, (state: IUserReducer, payload: IUser[]) => ({
    ...state,
    subUser: { ...state.subUser, subUsers: payload },
}));

user.on(signOutUser, () => initialState);
