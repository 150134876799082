import axios from 'axios';
import { APIUrl } from '../apiConstants';

export const signInApi = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(APIUrl.login, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};

export const signUpApi = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(APIUrl.register, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};

export const autoLoginAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(APIUrl.autoLogin, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};

export const getUserProfile = () => {
    return axios.get(APIUrl.profile, {
        timeout: 10000,
    });
};
export const updateUserProfile = (paylaod: any) => {
    return axios.post(APIUrl.profile, paylaod, {
        timeout: 10000,
    });
};


export const getRoutesAPI = () => {
    return axios.get(APIUrl.myAccess, {
        timeout: 10000,
    });
};

export const forgotPasswordApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.forgotPassword}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const resetPasswordApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.resetPassword}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const verifyAccountApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.verifyAccount}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};

export const requestNewCardDetails = (stripe: any, cardData: any, options: any) => {
    return stripe.createToken(cardData, options);
};

export const getCredits = () => {
    return axios.get(APIUrl.credits, {
        timeout: 10000,
    });
};

export const getAllPlans = () => {
    return axios.get(APIUrl.getAllPlans, {
        timeout: 10000,
    });
};

export const updatePlan = (planId: number) => {
    return axios.get(`${APIUrl.updatePlan}/${planId}`, {
        timeout: 10000,
    });
};

export const getLastInvoice = () => {
    return axios.get(APIUrl.lastInvoice, {
        timeout: 10000,
    });
};

export const addCredits = (amount: number) => {
    return axios.post(APIUrl.addCredits,  { charge: amount }, {
        timeout: 10000,
    });
};

export const addNewCard = (data: any) => {
    return axios.post(APIUrl.updateCard, data, {
        timeout: 10000,
    });
};

export const getNewCard = () => {
    return axios.get(APIUrl.getCard, {
        timeout: 10000,
    });
};


export const getSubUsers = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.get(
        `${APIUrl.subUsers}/?page=${payload.page || 1}&limit=${payload.limit}&filterBy=${
        payload.filterBy || ''
            }&searchText=${payload.searchText || ''}`,
        {
            headers: emailheader,
            timeout: 10000,
        },
    );
};

export const createSubUserAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    if (payload.id) {
        return axios.patch(`${APIUrl.subUsers}/${payload.id}`, payload, {
            headers: emailheader,
            timeout: 10000,
        });
    } else {
        return axios.post(`${APIUrl.subUsers}/`, payload, {
            headers: emailheader,
            timeout: 10000,
        });
    }
};
export const getCardDetails = (stripe: any, cardid: any) => {
    return stripe.retrieveSource({ id: cardid });
};
