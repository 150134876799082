import { createAction } from "redux-act";

export const fetchTemplates = createAction('to fetch templates');
export const createNewTemplates = createAction<any>('to create templates');
export const setTemplates = createAction<any>('to set templates');
export const setTotalTemplateRecords = createAction<any>('to set templates total records');
export const changeTemplatesCurrentPage = createAction<number>('set Templates current page');
export const changeTemplatesPerPage = createAction<number>('set Templates per page');
export const setNewTemplateErrorValue = createAction<any>('set new templates errors');
export const setNewTemplateFormValue = createAction<any>('set new templates form');
export const resetNewTemplateForm = createAction('reset new templates form');
export const updateTemplate = createAction<any>('set update templates');
export const deleteTemplate = createAction<number>('delete existing template');
export const changeNewTemplateFieldValue = createAction<any>('change template field value');

export const fetchBroadcast = createAction('to fetch Broadcast');
export const createNewBroadcast = createAction<any>('to create Broadcast');
export const setBroadcast = createAction<any>('to set Broadcast');
export const setTotalBroadcastRecords = createAction<any>('to set Broadcast total records');
export const changeBroadcastCurrentPage = createAction<number>('set Broadcast current page');
export const setNewBroadcastErrorValue = createAction<any>('set new Broadcast errors');
export const changeBroadcastPerPage = createAction<number>('set Broadcast per page');
export const changeNewBroadcastFieldValue = createAction<any>('change broadcast field value');
export const setNewBroadcastFormValue = createAction<any>('set new broadcast form');
export const resetNewBroadcastForm = createAction('reset new Broadcast form');
export const updateBroadcast = createAction<any>('set update Broadcast');
export const deleteBroadcast = createAction<number>('delete existing Broadcast');
export const toggleBroadcastLoader = createAction<boolean>('toggle broadcast loader');

export const fetchResponder = createAction<{ type: string }>('to fetch responder');
export const createNewResponder = createAction<any>('to create auto responder');
export const setResponder = createAction<any>('to set responder');
export const setTotalResponderRecords = createAction<any>('to set responder total records');
export const changeResponderCurrentPage = createAction<number>('set responder current page');
export const setNewResponderErrorValue = createAction<any>('set new responder errors');
export const changeResponderPerPage = createAction<number>('set responder per page');
export const changeNewResponderFieldValue = createAction<any>('change responder field value');
export const setNewResponderFormValue = createAction<any>('set new responder form');
export const resetNewResponderForm = createAction('reset new responder form');
export const updateResponder = createAction<any>('set update responder');
export const deleteResponder = createAction<number>('delete existing responder');
