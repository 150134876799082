import { createReducer } from 'redux-act';
import { fetchNumbers, signOutUser, setNumbers, toggleLoader } from '../actions';

export interface TwilioNumbers {}

export interface ITwilioNumberReducer {
    numbers: TwilioNumbers[];
    isLoading: boolean;
}

const initialState = {
    numbers: [],
    isLoading: false,
};

export const buynumbers = createReducer<ITwilioNumberReducer>({}, initialState);

buynumbers.on(fetchNumbers, (state: ITwilioNumberReducer) => ({
    ...state,
    isLoading: true,
}));

buynumbers.on(setNumbers, (state: ITwilioNumberReducer, payload: TwilioNumbers[]) => ({
    ...state,
    numbers: payload,
}));
buynumbers.on(toggleLoader, (state: ITwilioNumberReducer, payload: boolean) => ({
    ...state,
    isLoading: payload,
}));
buynumbers.on(signOutUser, () => initialState);
