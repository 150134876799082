import React from 'react';

const BillingConfig = {
    routes: [
        {
            path: '/settings/billing',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./Billing')),
        },
    ],
};
export default BillingConfig;
