import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import LogoComponent from './Logo';
import useDetectMobileView from '../modules/hooks/useDetectMobileView';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../modules/actions';
import SingleMenuLink from './Common/SingleMenuLink';
import LevelMenuLinks from './Common/LevelMenuLinks';
import { getRoutes } from '../modules/selectors';
const TopBar = () => {
    const dispatch = useDispatch();

    const isMobile = useDetectMobileView();
    const routes = useSelector(getRoutes);
    const toggleSideMenuCallback = useCallback(() => {
        dispatch(actions.toggleSideMenu());
    }, [dispatch]);

    return (
        <aside
            id="layout-menu"
            className={`${classnames({
                'layout-menu-horizontal': !isMobile,
                'menu-horizontal': !isMobile,
                'container-fluid': !isMobile,
                'flex-grow-0': !isMobile,
                'layout-menu': isMobile,
                'menu-vertical': isMobile,
            })} menu bg-menu-theme`}
        >
            <div className={`container-xxl d-flex h-100 ${classnames({ 'flex-column': isMobile, 'p-0': isMobile })}`}>
                <div
                    className={`navbar-brand app-brand d-xl-flex py-0 me-4 my-2 ${classnames({ 'd-none': !isMobile })}`}
                >
                    <Link to="" className="app-brand-link gap-2">
                        <span className="app-brand-logo demo">
                            <LogoComponent varient={'transparent'} />
                        </span>
                    </Link>

                    <a
                        href="javascript:void(0);"
                        className="layout-menu-toggle menu-link text-large ms-auto d-xl-none"
                        onClick={toggleSideMenuCallback}
                    >
                        <i className="ti ti-x ti-sm align-middle" />
                    </a>
                </div>
                <ul className="menu-inner">
                    {routes.map((route, index) => {
                        return route.routes.length === 0 ? (
                            <SingleMenuLink text={route.parentLinkText} navigateTo={route.partialLink} icon={route.icon} key={`topbar_${index}`}/>
                        ) : (
                            <LevelMenuLinks
                                links={route.routes}
                                parentLinkText={route.parentLinkText}
                                partialLink={route.partialLink}
                                icon={route.icon}
                                key={`topbar_${index}`}
                            />
                        );
                    })}
                    {/*<SingleMenuLink text={'Conversations'} navigateTo={'/'} />
                    <LevelMenuLinks
                        links={addressBookLinks.current}
                        parentLinkText={'Address book'}
                        partialLink={'/address-book'}
                        icon={"id"}
                    />
                    <LevelMenuLinks
                        links={messageToolsLinks.current}
                        parentLinkText={'Messaging tools'}
                        partialLink={'/message-tools'}
                        icon={"layout-sidebar"}
                    />
                    <LevelMenuLinks
                        links={settingsLinks.current}
                        parentLinkText={'Settings'}
                        partialLink={'/settings'}
                        icon={"settings"}
                    />
                    <LevelMenuLinks
                        links={referralLinks.current}
                        parentLinkText={'Referral'}
                        partialLink={'/referral'}
                        icon={"a-b-2"}
                    />
                    <LevelMenuLinks
                        links={anylyticsLinks.current}
                        parentLinkText={'Analytics'}
                        partialLink={'/analytics'}
                        icon={"chart-dots-2"}
                    />*/}
                </ul>
            </div>
        </aside>
    );
};
export default TopBar;
