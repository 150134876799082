import { IStoreState } from "../types";

export const getAddresses = (state: IStoreState) =>
    state.addresses.addresses;

export const getAddressCurrentPage = (state: IStoreState) =>
    state.addresses.currentPage;


export const getAddressPerPage = (state: IStoreState) =>
    state.addresses.perPage;


export const getNewAddressFormData = (state: IStoreState) =>
    state.addresses.newAddress;
export const getNewAddressFormErrors = (state: IStoreState) =>
    state.addresses.newAddressErrors;
export const getTotalAddressRecords = (state: IStoreState) =>
    state.addresses.totalRecords;

export const getAddressTags = (state: IStoreState) =>
    state.addresses.tags;
