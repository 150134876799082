import React from "react";

const LoginConfig = {
  routes: [
    {
      path: "/",
      component: React.lazy(() => import("./LoginScreen"))
    },
    {
      path: "/forgot-password",
      component: React.lazy(() => import("./ForgotPasswordScreen"))
    },
    {
      path: "/reset-password/:code",
      component: React.lazy(() => import("./ResetPasswordScreen"))
    },
    {
      path: "/auto-login/:code",
      component: React.lazy(() => import("./AutoLoginScreen"))
    },
    {
      path: "/verify",
      component: React.lazy(() => import("./VerifySignupCodeScreen"))
    }
  ]
};
export default LoginConfig
