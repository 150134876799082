import React from 'react';

const DashboardConfig = {
    routes: [
        {
            path: '/',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./DashboardScreen')),
        },
    ],
};
export default DashboardConfig;
