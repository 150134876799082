import React from 'react';

const WidgetConfig = {
    routes: [
        {
            path: '/settings/widget',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./WidgetPage')),
        }
    ],
};
export default WidgetConfig;
