import axios from 'axios';
import { APIUrl } from '../apiConstants';

export const createUpdateMicroUrlAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (payload.id) {
        return axios.patch(`${APIUrl.urlShortener}/${payload.id}`, payload, {
            headers,
            timeout: 10000,
        });
    } else {
        return axios.post(`${APIUrl.urlShortener}/`, payload, {
            headers,
            timeout: 10000,
        });
    }
};

export const getMicroUrlAPI = ({ page, limit, is_referral }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    let str = `?page=${page}&limit=${limit}`;
    if (is_referral) {
        str += '&is_referral=' + is_referral;
    }
    return axios.get(`${APIUrl.urlShortener}/${str}`, {
        headers,
        timeout: 10000,
    });
};

export const getReferralUrlAPI = ({ page, limit, id }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const str = `?page=${page}&limit=${limit}`;
    return axios.get(`${APIUrl.referral}/${id}${str}`, {
        headers,
        timeout: 10000,
    });
};

export const createReferralUrlAPI = ({ name, code, phone_number }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.referral}/validate`, { name, code, phone_number }, {
        headers,
        timeout: 10000,
    });
};

export const createNewReferralAPI = ({ message, address_ids }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.referral}/`, { message, address_ids }, {
        headers,
        timeout: 10000,
    });
};

export const redeemReferralAPI = (id: number) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.patch(`${APIUrl.referral}/claim/${id}`, {}, {
        headers,
        timeout: 10000,
    });
};

export const createPreDraftMessageAPI = ({ message, referral_message }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.referral}/pre-draft`, { message, referral_message }, {
        headers,
        timeout: 10000,
    });
};
export const getPreDraftMessageAPI = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.referral}/pre-draft` , {
        headers,
        timeout: 10000,
    });
};

export const deleteMicroUrlAPI = ({ id }: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.delete(`${APIUrl.urlShortener}/${id}`, {
        headers,
        timeout: 10000,
    });
};
