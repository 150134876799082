export const APIUrl = {
  login: '/auth/login',
  register: '/auth/register',
  autoLogin: '/auth/autologin',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  verifyAccount: '/auth/verify',
  profile: '/user/profile',
  subUsers: '/user/sub-user',
  myAccess: '/user/my-access',
  address: '/address',
  urlShortener: '/url-shortener',
  referral: '/referral',
  buyNumbers: '/twilio',
  // hostedNumber: '/twilio/hosted-number',
  hostedNumber: '/twilio/hosted-number',
  mmsUpload: '/twilio/mms-upload',
  registerDevice: '/user/register-device',
  isvBasicInformation: '/twilio/basic-information',
  isvCampaignInformation: '/twilio/campaign-information',
  isvTollfreeInformation: '/twilio/tollfree-information',
  templates: '/templates/',
  broadcast: '/broadcast',
  autoResponder: '/auto-responder',
  changeAddressStatus: '/address/change-status',
  addressImport: '/address/import',
  credits: '/stripe/credits',
  getAllPlans: '/stripe/get-plan-details',
  updatePlan: '/stripe/update-plan',
  lastInvoice: '/stripe/last-invoice',
  addCredits: '/stripe/add-credits',
  updateCard: '/stripe/update-card',
  getCard: '/stripe/get-card',
};
export const ENV_TYPE = {
  DEV: 'Dev',
  PROD: 'Prod',
};
export const ENV: any = {
  // [ENV_TYPE.PROD]: 'http://localhost:8082/api/v1',
  // [ENV_TYPE.DEV]: 'https://application.trutextapp.com/api/v1',
  [ENV_TYPE.PROD]: 'https://staging.trutextapp.com/api/v1',
};
export const ENV_BASE_URL: any = {
  // [ENV_TYPE.PROD]: 'http://localhost:8082',
  [ENV_TYPE.PROD]: 'https://staging.trutextapp.com/api/v1',
  // [ENV_TYPE.DEV]: 'https://application.trutextapp.com',
  // [ENV_TYPE.PROD]: 'https://application.trutextapp.com',
};
