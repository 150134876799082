import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

export const getSocket = (url: string, token: string): Socket => {
    if (!socket) {
        socket = io(url, {
            auth: {
                token,
            },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionAttempts: 10,
        });
    }
    return socket;
};

export const deActivateSocket = () => {
    if (socket) {
        // console.log('===DISCONNOCT====');
        socket.disconnect();
        socket.close();
        socket = null;
    }
};


export const refreshApp = () => {
    return new Promise(resolve => {
       setTimeout(() => {
           window.location.reload();
           resolve(true);
       }, 200)
    });
};
