import { ISagaAction } from '../types';
import { SagaIterator } from 'redux-saga';
import {
    addCredits,
    addNewCard,
    getAllPlans,
    getCredits,
    getLastInvoice,
    getNewCard,
    requestNewCardDetails,
    updatePlan,
} from '../utils/Apis/user';
import { select, put, call } from 'redux-saga/effects';
import { showAlert } from '../utils/AlertUtils';
import * as actions from '../actions/index';
import { getComponentRoutes } from '../selectors';

export const storeNewCard = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { stripe, cardData, options, selectedPlan } = action.payload;
        const result = yield call(requestNewCardDetails, stripe, cardData, options);
        console.log(result);
        if (result.error) {
            const data = {
                title: 'Error!',
                text: result?.error?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        } else {
            yield call(addNewCard, { ...result.token, tokenId: result.token.id });
            const data = {
                title: 'Success!',
                text: 'Card successfully added.',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        if (selectedPlan) {
            yield put(actions.updatePlan(selectedPlan));
        }
        // yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        const data = {
            title: 'Error!',
            text: e?.error?.message,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};

export const getNewCardAPI = function* (): SagaIterator {
    try {
        const result = yield call(getNewCard);
        // console.log(result);
        yield put(actions.setPrefetchedCardData(result.data.data));
    } catch (e: any) {
        console.log(e);
        const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};

export const getCreditsAPI = function* (action: ISagaAction<any>): SagaIterator {
    try {
        // const result = yield call(getCardDetails, stripe, "card_1NFvhOGMu8bVrcSDZwnhsFYJ");
        // console.log(result);
        const result = yield call(getCredits);
        yield put(actions.setCredits(parseFloat(result.data.data.credits)));
    } catch (e: any) {
        console.log(e);
        const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};
export const getAllPlansAPI = function* (): SagaIterator {
    try {
        const result = yield call(getAllPlans);
        yield put(actions.setAllPlans(result.data.data));
    } catch (e: any) {
        console.log(e);
        const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};

export const updatePlanAPI = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield call(updatePlan, action.payload);
        yield put(actions.getAllPlans());
        const routes = yield select(getComponentRoutes);
        if (routes.length === 1) {
            yield put(actions.getRoutes());
        }
        const data = {
            title: 'Success!',
            text: 'Plan successfully changed!',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        yield put(actions.togglePlanchooserModal(false));
        yield put(actions.getCardDetails());
        yield put(actions.getCredits());
        yield put(actions.getLastInvoiceAction());
    } catch (e: any) {
        console.log(e);
        const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};

export const getLastInvoiceAPI = function* (): SagaIterator {
    try {
        const result = yield call(getLastInvoice);
        yield put(actions.setLastInvoice(result.data.data));
    } catch (e: any) {
        console.log(e);
        yield put(actions.setLastInvoice(null));
        /*const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);*/
        // alert(e?.response?.data?.errors);
    }
};

export const addCreditsAPI = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.toggleStripeLoader(true));
        yield call(addCredits, action.payload);
        const data = {
            title: 'Success!',
            text: 'Credits added successfully!',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        yield put(actions.toggleAddCreditsModal(false));
        yield put(actions.getCredits());
        yield put(actions.toggleStripeLoader(false));
    } catch (e: any) {
        console.log(e);
        yield put(actions.toggleStripeLoader(false));
        yield put(actions.setLastInvoice(null));
        const data = {
            title: 'Error!',
            text: e?.error?.message || e?.response?.data?.error?.message || e?.response?.data?.errors || e?.errors,
            icon: 'error',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // alert(e?.response?.data?.errors);
    }
};
