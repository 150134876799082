import { IStoreState } from "../types";

export const getNewMicroUrlData = (state: IStoreState) =>
    state.analytics.newMicroUrlData;

export const getNewMicroUrlError = (state: IStoreState) =>
    state.analytics.newMicroUrlDataError;

export const getMicroUrlsCurrentPage = (state: IStoreState) =>
    state.analytics.currentPage;

export const getMicroUrlsPerPage = (state: IStoreState) =>
    state.analytics.perPage;

export const getTotalMicroUrlsRecords = (state: IStoreState) =>
    state.analytics.totalRecords;

export const getMicroUrls = (state: IStoreState) =>
    state.analytics.microUrls;

export const getReferralData = (state: IStoreState) =>
    state.analytics.referralData;

export const getTotalReferralRecords = (state: IStoreState) =>
    state.analytics.referralTotalRecords;

export const getReferralCurrentPage = (state: IStoreState) =>
    state.analytics.referralCurrentPage;

export const getReferralPerPage = (state: IStoreState) =>
    state.analytics.referralPerPage;

export const getNewReferralData = (state: IStoreState) =>
    state.analytics.newReferralData;
export const getNewReferralErrorData = (state: IStoreState) =>
    state.analytics.newReferralDataError;

export const getReferralMessage = (state: IStoreState) =>
    state.analytics.newReferralMessage;
