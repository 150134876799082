import React from 'react';

const AutoLoginConfig = {
    routes: [
        {
            path: '/auto-login/:code',
            component: React.lazy(() => import('./AutoLoginScreen')),
        },
    ],
};
export default AutoLoginConfig;
