import React, { Suspense, useEffect, useMemo } from 'react';
import Router from './router/Router';
import { authRoutes, noAuthRoutes } from './router';
import { useAppContext } from './context/AppContext';
import Layout from './Layout';
import LogoComponent from './components/Logo';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './modules/actions';
import { useNavigate } from 'react-router';
import AutoLoginConfig from './pages/NoAuth/Login/AuthLoginConfig';
import * as selector from "./modules/selectors";
const windows: any = window;
const RouterLayout = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = useSelector(selector.getIsUserAuth);
    const allPlans = useSelector(selector.getAllPlans);
    const currentPlan = useMemo(() => allPlans.find((x) => x.is_current_plan), [allPlans]);
    const componentRoutes = useSelector(selector.getComponentRoutes);
    const { setRoutes } = useAppContext();

    useEffect(() => {
        if (isAuth) {
            dispatch(actions.getRoutes());
        }
    }, [dispatch, isAuth]);

    useEffect(() => {
        if (!windows.$zoho) {
            windows.$zoho = {};
        }
        if (currentPlan && currentPlan.plan_name === "Pro Plan") {
            windows.$zoho.salesiq = {
                widgetcode:
                    '746f9e96f155395b1d731860bf1b4224516730524199be9dcb448eea1102cbbb29b3d02634748cc37617a967811a8c65',
                values: {},
                ready: function () {},
            };
        } else {
            windows.$zoho.salesiq = {
                widgetcode: '61c26c2507bcd66a521a075d2848bd1cd14d76e9637e6b35eb0939d09cd0ecad',
                values: {},
                ready: function () {},
            };
        }
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'zsiqscript';
        script.defer = true;
        script.src = 'https://salesiq.zoho.com/widget';
        const scriptTag: any = document.getElementsByTagName('script')[0];
        scriptTag.parentNode.insertBefore(script, scriptTag);
    }, [navigate, currentPlan]);

    useEffect(() => {
        if (isAuth) {
            const routes = authRoutes.filter((x) => componentRoutes.indexOf(x.path) !== -1);
            setRoutes([...routes, ...AutoLoginConfig.routes]);
            dispatch(actions.activateSocket());
            if (routes.length === 1) {
                navigate(routes[0].path);
            }
        } else {
            setRoutes(noAuthRoutes);
        }
    }, [navigate, componentRoutes, setRoutes, dispatch, isAuth]);

    return (
        <React.Fragment>
            <Suspense
                fallback={
                    <LoadingWrapper>
                        <LogoComponent varient={'transparent'} customWidth={'200px'} />
                    </LoadingWrapper>
                }
            >
                <Layout>
                    <Router />
                </Layout>
            </Suspense>
        </React.Fragment>
    );
};
export default RouterLayout;
const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;
