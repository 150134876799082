import React from 'react';
import LoginConfig from '../pages/NoAuth/Login/LoginConfigs';
import TextRootsUtils from '../modules/utils/Utils';
import { Navigate } from 'react-router-dom';
import DashboardConfig from '../pages/Auth/Dashboard/DashboardConfigs';
import AddressConfig from '../pages/Auth/AddressBook/AddressConfigs';
import MessagingToolsConfig from '../pages/Auth/MessagingTools/MessagingToolsConfigs';
import ProfileConfig from '../pages/Auth/Settings/Profile/ProfileConfigs';
import AnalyticsConfigs from '../pages/Auth/Analytics/AnalyticsConfigs';
import SignupConfig from '../pages/NoAuth/Signup/SignupConfigs';
import BuyNumberConfig from '../pages/Auth/BuyNumber/BuyNumberConfigs';
import ISVFormsConfig from '../pages/Auth/ISVForms/ISVFormsConfigs';
import WidgetConfig from '../pages/Auth/Settings/Widget/WidgetConfigs';
import ReferralsConfig from '../pages/Auth/Settings/Referrals/ReferralsConfigs';
import BillingConfig from '../pages/Auth/Settings/Billing/BillingConfig';
import HostNumberConfig from '../pages/Auth/Settings/HostNumber/HostNumerConfig';
import AutoLoginConfig from '../pages/NoAuth/Login/AuthLoginConfig';
import UsersConfigs from '../pages/Auth/Users/UsersConfigs';
import AccessDeniedConfig from "../pages/NoAuth/AccessDenied/AccessDeniedConfigs";

const routeConfigs = [
    DashboardConfig,
    AddressConfig,
    MessagingToolsConfig,
    ProfileConfig,
    AnalyticsConfigs,
    BuyNumberConfig,
    ISVFormsConfig,
    WidgetConfig,
    ReferralsConfig,
    BillingConfig,
    HostNumberConfig,
    AutoLoginConfig,
    UsersConfigs,
    AccessDeniedConfig,
];

const noAuthRouteConfigs = [LoginConfig, SignupConfig, AccessDeniedConfig];

export const authRoutes = [
    ...TextRootsUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '*',
        exact: true,
        isPrivate: false,
        component: () => <Navigate to="/" />,
    },
];
export const customRoute = (path: string, navigationPath: string) => ({
    path: path,
    exact: true,
    isPrivate: false,
    component: () => <Navigate to={navigationPath} />,
});
export const noAuthRoutes = [
    ...TextRootsUtils.generateRoutesFromConfigs(noAuthRouteConfigs),
    {
        path: '*',
        exact: true,
        isPrivate: false,
        component: () => <Navigate to="/" />,
    },
];
