import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleSideMenu } from '../../modules/actions';

interface ISingleMenuLink {
    text: string;
    icon: string;
    navigateTo: string;
}
const SingleMenuLink: React.FC<ISingleMenuLink> = ({ navigateTo, text, icon }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const toggleSideMenuCallback = useCallback(() => {
        dispatch(toggleSideMenu());
    }, [dispatch]);

    return (
        <li
            className={`${classnames({
                active: location.pathname === navigateTo,
            })} menu-item`}
        >
            <Link to={navigateTo} className="menu-link" onClick={toggleSideMenuCallback}>
                <i className={`menu-icon tf-icons ti ti-${icon}`} />
                <div data-i18n="Dashboards">{text}</div>
            </Link>
        </li>
    );
};
export default SingleMenuLink;
