import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './context/AppContext';
import store, { persistor } from './modules/store';
import GlobalStyle from './css/global-style';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import './firebase';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/theme-css/core.css';
import './css/theme-css/node-waves.css';
import './css/theme-css/perfect-scrollbar.css';
import './css/theme-css/tabler-icons.css';
import './css/theme-css/theme-default.css';
import RouterLayout from './RouterLayout';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import NetworkInterceptor from './modules/utils/interceptor';
import { ErrorBoundary } from "./components/ErrorBoundry";
NetworkInterceptor.setupInterceptors(store);
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    Sentry.init({
        dsn: 'https://c5f08bd061182726567c78efdf9af055@o4505631138709504.ingest.sentry.io/4505631140544512',
        release: 'TruText@1.0.1',
        integrations: [
            new Integrations.BrowserTracing(),
            // This will give ability to see correct path in the dev tools
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <AppProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename={'/'}>
                    <GlobalStyle />
                    <ErrorBoundary>
                        <RouterLayout />
                    </ErrorBoundary>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AppProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
