import React from "react";

const SignupConfig = {
  routes: [
    {
      path: "/signup",
      component: React.lazy(() => import("./SignupScreen"))
    }
  ]
};
export default SignupConfig
