import React from 'react';

const ReferralsConfig = {
    routes: [
        {
            path: '/referrals',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./ReferralsPage')),
        },
        {
            path: '/referrals/validate',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./ValidateCode')),
        },
        {
            path: '/referrals/lookup',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./ReferralsLookupPage')),
        },
        {
            path: '/referrals/lookup/:id',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./ReferralsLookupChildPage')),
        },
        {
            path: '/referrals/reporting',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./ReferralReportingPage')),
        },
    ],
};
export default ReferralsConfig ;
