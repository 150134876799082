import React from 'react';

const BuyNumberConfig = {
    routes: [
        {
            path: '/buy-number',
            private: true,
            hasLayout: true,
            hasHeader: false,
            component: React.lazy(() => import('./BuyNumberScreen')),
        },
    ],
};
export default BuyNumberConfig;
