import { eventChannel, END } from 'redux-saga';
import { getSocket } from '../socket';

export const getSocketEvents = (baseUrl: string, token: string, eventType: string) => {
    const socket = getSocket(baseUrl, token);
    return eventChannel((emitter) => {
        socket.on(eventType, (data: any) => {
            // const enc = new TextDecoder(); // always utf-8
            // emitter(JSON.parse(enc.decode(data)));
            emitter(data);
        });
        return () => {
            socket.off(eventType, () => {});
            emitter(END);
        };
    });
};

export const emitSocketEvent = (baseUrl: string, token: string, eventName: string, payload: any) => {
    const socket = getSocket(baseUrl, token);
    // const enc = new TextEncoder(); // always utf-8
    // const data = enc.encode(JSON.stringify(payload));
    // socket.emit(eventName, data);
    socket.emit(eventName, payload);
};
