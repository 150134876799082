import { SagaIterator } from 'redux-saga/index';
import { ISagaAction } from '../types';
import { put, select, call } from 'redux-saga/effects';
import {
    addressImportAPI,
    changeAddressStatusAPI,
    createAddressAPI,
    getAddressAPI,
    getAllTags,
} from '../utils/Apis/addresses';
import { getAddressCurrentPage, getAddressPerPage } from '../selectors';
import * as actions from '../actions';
import { showAlert } from '../utils/AlertUtils';

export const getAllAddress = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { type, filterBy, searchText } = action.payload;
        const page = yield select(getAddressCurrentPage);
        const limit = yield select(getAddressPerPage);
        const result = yield call(getAddressAPI, { type, page, limit, filterBy, searchText });
        yield put(actions.setAddress(result.data.data.rows));
        yield put(actions.setTotalAddressRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};
export const createNewAddress = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewAddressErrorValue(null));
        const { id, email, first_name, last_name, phone_number, tag_name, status } = action.payload;
        yield call(createAddressAPI, {
            id,
            email,
            first_name,
            last_name,
            tag_name,
            phone_number: phone_number,
        });
        yield put(actions.toggleAddNewAddressModal(false));
        yield put(actions.resetNewAddressForm());
        yield put(
            actions.fetchAddress({ type: status === 'DISABLED' ? 'blocked' : window.location.pathname.indexOf('blocked') !== -1 ? 'active' : 'active' }),
        );
        if (window.location.pathname === '/') {
            yield put(actions.fetchAllConversation());
        }
        const data = {
            title: 'Success!',
            text: `Addresses successfully ${id ? 'updated' : 'created'}.`,
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // yield put(authUser());
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewAddressErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};
export const changeStatus = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const data = {
            title: 'Caution!',
            text: 'Are you sure you want to change the status?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        const alertData = yield call(showAlert, data);
        if (alertData.isConfirmed) {
            const { id, status, fetchConversation } = action.payload;
            const result = yield call(changeAddressStatusAPI, {
                id,
                status,
            });
            console.log(result);
            yield put(actions.resetNewAddressForm());
            yield put(
                actions.fetchAddress({
                    type: window.location.pathname.indexOf('blocked') !== -1 ? 'blocked' : 'active',
                }),
            );
            if (fetchConversation) {
                yield put(actions.fetchAllConversation());
            }
            const data = {
                title: 'Success!',
                text: 'Addresses status successfully changed.',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // yield put(authUser());
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewAddressErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};

export const importAddresses = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const file = action.payload;
        const result = yield call(addressImportAPI, file);
        console.log(result);
        yield put(
            actions.fetchAddress({ type: window.location.pathname.indexOf('blocked') !== -1 ? 'active' : 'active' }),
        );
        yield put(actions.toggleImportAddressModal(false));
        const data = {
            title: 'Success!',
            text: 'Addresses successfully imported.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // yield put(authUser());
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const getAllTagsSaga = function* (): SagaIterator {
    try {
        const result = yield call(getAllTags);
        yield put(actions.setExistingAddressTags(result.data.data));
        yield put(actions.toggleImportAddressModal(false));
    } catch (e: any) {
        console.log(e);
    }
};
