import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useDetectMobileView from '../modules/hooks/useDetectMobileView';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../modules/selectors';

const Compliance = () => {
    const isMobile = useDetectMobileView();
    const user = useSelector(getCurrentUser);
    return (
        <Wrapper isMobile={isMobile} className={isMobile ? "px-2": ""}>
            <Title isMobile={isMobile}>Compliance{isMobile ? ':' : ' :\u00A0'}</Title>
            <Body>
                {' '}
                {user?.is_tollfree_number && (
                    <React.Fragment>
                        To send texts messages using Toll Free numbers, you need to register your organization. Get
                        started by filling out your <Link to="/isv-forms">organization’s info</Link>.
                    </React.Fragment>
                )}
                {!user?.is_tollfree_number && (
                    <React.Fragment>
                        To send texts in the United States, you need to register your organization. Get started by
                        filling out your <Link to="/isv-forms">organization’s info.</Link>
                    </React.Fragment>
                )}
            </Body>
        </Wrapper>
    );
};
const Wrapper = styled.div<{ isMobile: boolean }>`
    height: ${({ isMobile }) => (isMobile ? '100px' : '70px')};
    background-color: #fff3cc;
    border-color: #ffcf48;
    color: #131618;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    span {
        font-size: ${({ isMobile }) => (isMobile ? '0.8rem' : '16px')};
    }
`;
const Title = styled.div<{ isMobile: boolean }>`
    display: inline-block;
    font-size: 16px;
    font-variant-caps: initial;
    font-weight: 600;
    ${({ isMobile }) => (isMobile ? 'min-width: 100px;' : '')}
`;
const Body = styled.span`
    font-variant-caps: initial;
`;
export default Compliance;
