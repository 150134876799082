import { createAction } from "redux-act";
import { IMicroUrl } from "../reducers";

export const changeMicroUrlData = createAction<{ key:string; value: any }>('set micro url data');
export const changeNewReferralData = createAction<{ key:string; value: any }>('set referral data');
export const setNewMicroUrlData = createAction<any>('set row new micro url data');
export const setNewReferralData = createAction<any>('set row new referral data');
export const setNewMicroUrlErrorValue = createAction<any>('set micro url error');
export const setNewReferralErrorValue = createAction<any>('set referral error');
export const fetchMicroUrlData = createAction<any>('fetch all micro url data');
export const fetchReferralData = createAction<number>('fetch all referral data');
export const deleteMicroUrlData = createAction<number>('delete micro url data');
export const createNewMicroUrlData = createAction('add new micro url data');
export const createNewReferralData = createAction('add new referral data');
export const resetNewReferralData = createAction('reset new micro url data');
export const resetNewMicroUrlData = createAction('reset new micro url data');
export const setMicroUrlList = createAction<IMicroUrl[]>('set micro urls');
export const setReferralList = createAction<IMicroUrl[]>('set referral data');
export const changeMicroUrlsListCurrentPage = createAction<number>('set micro url current page');
export const changeMicroUrlsListPerPage = createAction<number>('set micro url per page');
export const setTotalMicroUrlsRecords = createAction<number>('set total micro url records ');
export const changeReferralListCurrentPage = createAction<number>('set referral current page');
export const changeReferralListPerPage = createAction<number>('set referral per page');
export const setTotalReferralRecords = createAction<number>('set total referral records ');
export const fetchPreDraftMessage = createAction('fetch predraft message');
export const savePreDraftMessage = createAction<{message: string; referral_message: string}>('save predraft message');
export const changeReferralMessage = createAction<string>('change Referral Message');
export const sendReferralMessage = createAction<{ message: string; address_ids: number[] }>('send Referral Message');
export const redeemReferral = createAction<number>('redeem Referral');
