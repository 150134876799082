import axios from 'axios';
import { APIUrl } from '../apiConstants';

export const getAddressAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    if (payload.type === undefined) {
        return axios.get(`${APIUrl.address}/all`, {
            headers: emailheader,
            timeout: 10000,
        });
    }
    return axios.get(
        `${APIUrl.address}/?type=${payload.type}&page=${payload.page || 1}&limit=${payload.limit}&filterBy=${
            payload.filterBy || ''
        }&searchText=${payload.searchText || ''}`,
        {
            headers: emailheader,
            timeout: 10000,
        },
    );
};
export const addressImportAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'multipart/form-data;',
    };
    const formData = new FormData();
    formData.append('file', payload);
    return axios.post(`${APIUrl.addressImport}`, formData, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const getAllTags = () => {
    const emailheader = {};
    return axios.get(`${APIUrl.address}/tags`, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const createAddressAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    if (payload.id) {
        return axios.patch(`${APIUrl.address}/${payload.id}`, payload, {
            headers: emailheader,
            timeout: 10000,
        });
    } else {
        return axios.post(`${APIUrl.address}/`, payload, {
            headers: emailheader,
            timeout: 10000,
        });
    }
};

export const changeAddressStatusAPI = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.patch(
        `${APIUrl.changeAddressStatus}/${payload.id}`,
        { status: payload.status },
        {
            headers: emailheader,
            timeout: 10000,
        },
    );
};
