import { IStoreState } from "../types";

export const getEnvironment = (state: IStoreState) =>
    state.userSettings.environment;

export const getComplianceStatus = (state: IStoreState) =>
    state.user?.user?.is_verified || state.userSettings.isUserCompliance;


export const getBrowserId = (state: IStoreState) =>
    state.userSettings.browserId;
