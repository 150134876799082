import { createReducer } from 'redux-act';
import {
    signOutUser,
    fetchTemplates,
    setTemplates,
    changeTemplatesCurrentPage,
    changeTemplatesPerPage,
    setNewTemplateErrorValue,
    resetNewTemplateForm,
    changeNewTemplateFieldValue,
    setNewTemplateFormValue,
    changeNewBroadcastFieldValue,
    setNewBroadcastFormValue,
    fetchBroadcast,
    setBroadcast,
    resetNewBroadcastForm,
    changeBroadcastPerPage,
    setNewBroadcastErrorValue,
    changeBroadcastCurrentPage,
    fetchResponder,
    setResponder,
    changeResponderCurrentPage,
    changeResponderPerPage,
    setNewResponderErrorValue,
    setNewResponderFormValue, changeNewResponderFieldValue, resetNewResponderForm, toggleBroadcastLoader,
} from '../actions';

export interface ITemplates {
    template_name: string;
    template_text: string;
    status: string;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    id: number;
}

export interface IBroadcast {
    id: number;
    title?: string | undefined | null;
    message?: string | undefined | null;
    entry_type?: string | undefined | null;
    schedule_date_time?: string | undefined | null;
    schedule_timezone?: string | undefined | null;
    status?: string | undefined | null;
    schedule_now?: boolean | undefined | null;
    opt_out_message?: boolean | undefined | null;
    created_at?: string | undefined | null;
    updated_at?: string | undefined | null;
    created_by_user?: {
        id: number;
        first_name?: string | undefined | null;
        last_name?: string;
        email?: string | undefined | null;
        phone_number?: string | undefined | null;
    };
    broadcast_numbers?: {
        id: number;
        country_code?: string | undefined | null;
        phone_number?: string | undefined | null;
    }[];
    broadcast_tags?: {
        id: number;
        tag_name?: string | undefined | null;
    }[];
}
export interface IAutoResponder {
    id: number;
    keyword?: string | undefined | null;
    text_message?: string | undefined | null;
    scheduled_start_time?: string | undefined | null;
    scheduled_end_time?: string | undefined | null;
    timezone?: string | undefined | null;
    auto_responder_type?: string | undefined | null;
    days?: string | undefined | null;
    previous_message?: string | undefined | null;
    received_message?: string | undefined | null;
    status?: string | undefined | null;
    created_at?: string | undefined | null;
    updated_at?: string | undefined | null;
    created_by_user?: {
        id: number;
        first_name?: string | undefined | null;
        last_name?: string;
        email?: string | undefined | null;
        phone_number?: string | undefined | null;
    };
}

export interface IMessageToolsReducer {
    templates: {
        data: ITemplates[];
        newTemplate?: any;
        newTemplateErrors?: any;
        totalRecords: number;
        perPage: number;
        currentPage: number;
        isLoading: boolean;
    };
    broadcast: {
        data: IBroadcast[];
        newBroadcast: any;
        newBroadcastErrors?: any;
        totalRecords: number;
        perPage: number;
        currentPage: number;
        isLoading: boolean;
    };
    responder: {
        data: IAutoResponder[];
        type: string,
        newResponder: any;
        newResponderErrors?: any;
        totalRecords: number;
        perPage: number;
        currentPage: number;
        isLoading: boolean;
    };
}

const initialState: IMessageToolsReducer = {
    templates: {
        data: [],
        newTemplate: undefined,
        newTemplateErrors: undefined,
        totalRecords: 0,
        currentPage: 1,
        perPage: 10,
        isLoading: false,
    },
    broadcast: {
        data: [],
        newBroadcast: {},
        newBroadcastErrors: undefined,
        totalRecords: 0,
        currentPage: 1,
        perPage: 10,
        isLoading: false,
    },
    responder: {
        data: [],
        type: "",
        newResponder: {},
        newResponderErrors: undefined,
        totalRecords: 0,
        currentPage: 1,
        perPage: 10,
        isLoading: false,
    },
};

export const messagetools = createReducer<IMessageToolsReducer>({}, initialState);
messagetools.on(fetchTemplates, (state: IMessageToolsReducer) => ({
    ...state,
    templates: {
        ...state.templates,
        isLoading: true,
    },
}));
messagetools.on(setTemplates, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    templates: {
        ...state.templates,
        data: payload,
        isLoading: false,
    },
}));
messagetools.on(changeTemplatesCurrentPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    templates: {
        ...state.templates,
        currentPage: payload,
    },
}));
messagetools.on(changeTemplatesPerPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    templates: {
        ...state.templates,
        perPage: payload,
    },
}));
messagetools.on(setNewTemplateErrorValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    templates: {
        ...state.templates,
        newTemplateErrors: payload,
    },
}));
messagetools.on(setNewTemplateFormValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    templates: {
        ...state.templates,
        newTemplate: payload,
    },
}));
messagetools.on(changeNewTemplateFieldValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    templates: {
        ...state.templates,
        newTemplate: {
            ...state.templates.newTemplate,
            [payload.key]: payload.value,
        },
        newTemplateErrors: {
            ...state.templates.newTemplateErrors,
            [payload.key]: '',
        },
    },
}));

messagetools.on(resetNewTemplateForm, (state: IMessageToolsReducer) => ({
    ...state,
    templates: {
        ...state.templates,
        newTemplate: undefined,
        newTemplateErrors: undefined,
    },
}));
messagetools.on(fetchBroadcast, (state: IMessageToolsReducer) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        isLoading: true,
    },
}));

messagetools.on(setBroadcast, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        data: payload,
        isLoading: false,
    },
}));
messagetools.on(changeBroadcastCurrentPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        currentPage: payload,
    },
}));
messagetools.on(changeBroadcastPerPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        perPage: payload,
    },
}));
messagetools.on(setNewBroadcastErrorValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        newBroadcastErrors: payload,
    },
}));
messagetools.on(toggleBroadcastLoader, (state: IMessageToolsReducer, payload: boolean) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        isLoading: payload,
    },
}));
messagetools.on(setNewBroadcastFormValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        newBroadcast: payload,
        newBroadcastErrors: undefined,
    },
}));
messagetools.on(changeNewBroadcastFieldValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        newBroadcast: {
            ...state.broadcast.newBroadcast,
            [payload.key]: payload.value,
        },
        newBroadcastErrors: {
            ...state.broadcast.newBroadcastErrors,
            [payload.key]: '',
        },
    },
}));

messagetools.on(resetNewBroadcastForm, (state: IMessageToolsReducer) => ({
    ...state,
    broadcast: {
        ...state.broadcast,
        newBroadcast: undefined,
        newBroadcastErrors: undefined,
    },
}));

messagetools.on(fetchResponder, (state: IMessageToolsReducer, payload: { type: string }) => ({
    ...state,
    responder: {
        ...state.responder,
        type: payload.type?.toUpperCase(),
        totalRecords: state.responder?.type !== payload.type ? 0 : state?.responder?.totalRecords,
        currentPage: state.responder?.type !== payload.type ? 1 : state?.responder?.currentPage,
        perPage: state.responder?.type !== payload.type ? 10 : state?.responder?.perPage,
        isLoading: true,
    },
}));

messagetools.on(setResponder, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    responder: {
        ...state.responder,
        data: payload,
        isLoading: false,
    },
}));
messagetools.on(changeResponderCurrentPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    responder: {
        ...state.responder,
        currentPage: payload,
    },
}));
messagetools.on(changeResponderPerPage, (state: IMessageToolsReducer, payload: number) => ({
    ...state,
    responder: {
        ...state.responder,
        perPage: payload,
    },
}));
messagetools.on(setNewResponderErrorValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    templates: {
        ...state.templates,
        newTemplateErrors: payload,
    },
}));
messagetools.on(setNewResponderFormValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    responder: {
        ...state.responder,
        newResponder: payload,
        newResponderErrors: undefined,
    },
}));
messagetools.on(changeNewResponderFieldValue, (state: IMessageToolsReducer, payload: any) => ({
    ...state,
    responder: {
        ...state.responder,
        newResponder: {
            ...state.responder.newResponder,
            [payload.key]: payload.value,
        },
        newResponderErrors: {
            ...state.responder.newResponderErrors,
            [payload.key]: '',
        },
    },
}));

messagetools.on(resetNewResponderForm, (state: IMessageToolsReducer) => ({
    ...state,
    responder: {
        ...state.responder,
        newResponder: undefined,
        newResponderErrors: undefined,
    },
}));

messagetools.on(signOutUser, () => initialState);
