import { createAction } from "redux-act";

export const setCardDetails = createAction<{ stripe: any; cardData: any; options: any; selectedPlan?: number | null; }>('set card details');
export const getCardDetails = createAction('get card details');
export const getCredits = createAction('get my credits');
export const setCredits = createAction<number>('set my credits');
export const setAllPlans = createAction<any>('set all plans');
export const setPrefetchedCardData = createAction<any>('set prefetched card data');
export const getAllPlans = createAction('get all plans');
export const updatePlan = createAction<number>('upgrade my plan');
export const getLastInvoiceAction = createAction('get last invoice');
export const setLastInvoice = createAction<any>('set last invoice');
export const toggleStripeLoader = createAction<boolean>('toggle laoder');
export const addCredits = createAction<number>('add credits');
