import { ISagaAction } from '../types';
import { SagaIterator } from 'redux-saga';
import {
    createBroadcastAPI,
    createResponderAPI,
    createTemplateAPI,
    deleteBroadcastAPI, deleteResponderAPI,
    deleteTemplateAPI,
    editBroadcastAPI,
    editResponderAPI,
    editTemplateAPI,
    getBroadcastAPI,
    getResponderAPI,
    getTemplatesAPI,
} from '../utils/Apis/templates';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { showAlert } from '../utils/AlertUtils';
import { put, select, call } from 'redux-saga/effects';
import moment from 'moment-timezone';

export const getAllTemplates = function* (): SagaIterator {
    try {
        const page = yield select(selectors.getTemplatesCurrentPage);
        const limit = yield select(selectors.getTemplatesPerPage);
        const result = yield call(getTemplatesAPI, { page, limit });
        yield put(actions.setTemplates(result.data.data.rows));
        yield put(actions.setTotalTemplateRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};
export const createNewTemplate = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewTemplateErrorValue(null));
        const { name, message } = action.payload;
        const result = yield call(createTemplateAPI, {
            name,
            message,
        });
        console.log(result);
        yield put(actions.toggleAddNewTemplateModal(false));
        yield put(actions.resetNewTemplateForm());
        yield put(actions.fetchTemplates());
        const data = {
            title: 'Success!',
            text: 'Template created successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewTemplateErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const updateTemplate = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewTemplateErrorValue(null));
        const { id, name, message } = action.payload;
        const result = yield call(editTemplateAPI, {
            id,
            name,
            message,
        });
        console.log(result);
        yield put(actions.toggleAddNewTemplateModal(false));
        yield put(actions.resetNewTemplateForm());
        yield put(actions.fetchTemplates());
        const data = {
            title: 'Success!',
            text: 'Template updated successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewTemplateErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const deleteTemplate = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const data = {
            title: 'Caution!',
            text: 'Are you sure you want to delete this template?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        const alertData = yield call(showAlert, data);
        if (alertData.isConfirmed) {
            const id = action.payload;
            const result = yield call(deleteTemplateAPI, {
                id,
            });
            console.log(result);
            yield put(actions.resetNewTemplateForm());
            yield put(actions.fetchTemplates());
            const alert2Data = {
                title: 'Success!',
                text: 'Template deleted successfully',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, alert2Data);
        }
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewTemplateErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const getAllBroadcast = function* (): SagaIterator {
    try {
        const page = yield select(selectors.getBroadcastCurrentPage);
        const limit = yield select(selectors.getBroadcastPerPage);
        const result = yield call(getBroadcastAPI, { page, limit });
        console.log(result);
        yield put(actions.setBroadcast(result.data.data.rows));
        yield put(actions.setTotalBroadcastRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};
export const createNewBroadcast = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.toggleBroadcastLoader(true));
        yield put(actions.setNewBroadcastErrorValue(null));
        const {
            title,
            message,
            entry_type,
            tags,
            schedule_now,
            scheduled_date,
            opt_out_message,
            scheduled_time,
            scheduled_timezone,
            manual_list,
            contact_list,
            navigation,
        } = action.payload;
        const result = yield call(createBroadcastAPI, {
            title,
            message,
            entry_type: entry_type || 'MANUAL',
            schedule_now,
            tags,
            manual_list,
            opt_out_message,
            contact_list,
            schedule_date: moment(scheduled_date).format('YYYY-MM-DD'),
            schedule_time: moment(scheduled_time).format('hh:mm a'),
            schedule_timezone: `${scheduled_timezone?.offset}`,
        });
        console.log(result);
        yield put(actions.resetNewBroadcastForm());
        yield put(actions.fetchBroadcast());
        const data = {
            title: 'Success!',
            text: 'Broadcast created successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        navigation('/message-tools/broadcast');
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
    yield put(actions.toggleBroadcastLoader(false));
};

export const updateBroadcast = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.toggleBroadcastLoader(true));
        yield put(actions.setNewBroadcastErrorValue(null));
        const {
            id,
            title,
            message,
            entry_type,
            tags,
            scheduled_date,
            opt_out_message,
            schedule_now,
            scheduled_time,
            scheduled_timezone,
            manual_list,
            contact_list,
            navigation,
        } = action.payload;
        const newTagValue = tags.map((x: string) => x.split(',')).flat();
        const result = yield call(editBroadcastAPI, {
            id,
            title,
            message,
            entry_type: entry_type || 'MANUAL',
            schedule_now,
            opt_out_message,
            tags: newTagValue,
            manual_list,
            contact_list,
            schedule_date: moment(scheduled_date).format('YYYY-MM-DD'),
            schedule_time: moment(scheduled_time).format('hh:mm a'),
            schedule_timezone: `${scheduled_timezone?.offset}`,
        });
        console.log(result);
        yield put(actions.toggleBroadcastLoader(false));
        yield put(actions.resetNewBroadcastForm());
        yield put(actions.fetchBroadcast());
        const data = {
            title: 'Success!',
            text: 'Broadcast updated successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        navigation('/message-tools/broadcast');
        yield call(showAlert, data);
    } catch (e: any) {
        yield put(actions.toggleBroadcastLoader(false));
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const deleteBroadcast = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const data = {
            title: 'Caution!',
            text: 'Are you sure you want to delete this Broadcast?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        const alertData = yield call(showAlert, data);
        if (alertData.isConfirmed) {
            const id = action.payload;
            const result = yield call(deleteBroadcastAPI, {
                id,
            });
            console.log(result);
            yield put(actions.resetNewBroadcastForm());
            yield put(actions.fetchBroadcast());
            const alert2Data = {
                title: 'Success!',
                text: 'Broadcast deleted successfully',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, alert2Data);
        }
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const getAllResponder = function* (): SagaIterator {
    try {
        const page = yield select(selectors.getResponderCurrentPage);
        const limit = yield select(selectors.getResponderPerPage);
        const type = yield select(selectors.getResponderType);
        const result = yield call(getResponderAPI, { page, limit, type });
        yield put(actions.setResponder(result.data.data.rows));
        yield put(actions.setTotalResponderRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};
export const createNewResponder = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewResponderErrorValue(null));
        const { keyword, message, start_time, end_time, timezone, days, previous_message, received_message } =
            action.payload;
        const type = yield select(selectors.getResponderType);
        yield call(createResponderAPI, {
            keyword,
            message,
            start_time: start_time ? moment(start_time).format('hh:mm:ss a') : undefined,
            end_time: end_time ? moment(end_time).format('hh:mm:ss a') : undefined,
            days,
            previous_message,
            received_message,
            type,
            timezone: timezone ? `${timezone?.offset}` : undefined,
        });
        yield put(actions.resetNewResponderForm());
        yield put(actions.fetchResponder({ type }));
        const data = {
            title: 'Success!',
            text: 'AutoResponder created successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const updateResponder = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewBroadcastErrorValue(null));
        const { id, keyword, message, start_time, end_time, timezone, days, previous_message, received_message } =
            action.payload;
        const type = yield select(selectors.getResponderType);
        yield call(editResponderAPI, {
            id,
            keyword,
            message,
            start_time: start_time ? moment(start_time).format('hh:mm:ss a') : undefined,
            end_time: end_time ? moment(end_time).format('hh:mm:ss a') : undefined,
            days,
            previous_message,
            received_message,
            type,
            timezone: timezone ? `${timezone?.offset}` : undefined,
        });
        yield put(actions.resetNewResponderForm());
        yield put(actions.fetchResponder({ type }));
        const data = {
            title: 'Success!',
            text: 'Auto Responder updated successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const deleteResponder = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const data = {
            title: 'Caution!',
            text: 'Are you sure you want to delete this Auto Responder?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        const alertData = yield call(showAlert, data);
        if (alertData.isConfirmed) {
            const id = action.payload;
            yield call(deleteResponderAPI, {
                id,
            });
            yield put(actions.resetNewResponderForm());
            const type = yield select(selectors.getResponderType);
            yield put(actions.fetchResponder({ type }));
            const alert2Data = {
                title: 'Success!',
                text: 'Auto Responder deleted successfully',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, alert2Data);
        }
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewBroadcastErrorValue(e?.response?.data?.errors));
        } else {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.message || '',
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};
