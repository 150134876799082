import { createReducer } from 'redux-act';
import {
    changeCampaignInformationFieldValue, changeTollfreeInformationFieldValue,
    setBasicInformation,
    setCampaignInformation, setTollfreeInformation,
    signOutUser,
    toggleISVInfoLoader,
} from '../actions';
import { changeBasicInformationFieldValue } from '../actions';

export interface IBasicInformation {
    organization_name: string | undefined | null;
    email: string | undefined | null;
    phone_number: string | undefined | null;
    street_secondary: string | undefined | null;
    street: string | undefined | null;
    city: string | undefined | null;
    region: string | undefined | null;
    postal_code: string | undefined | null;
    iso_country: string | undefined | null;
    business_name: string | undefined | null;
    social_media_profile_urls: string | undefined | null;
    website_url: string | undefined | null;
    business_regions_of_operation: string | undefined | null;
    business_type: string | undefined | null;
    business_registration_identifier: string | undefined | null;
    business_identity: string | undefined | null;
    business_industry: string | undefined | null;
    brand_error: string | undefined | null;
    brand_feedback: string[] | undefined | null;
    brand_identity_status: string | undefined | null;
    trust_bundle_status: string | undefined | null;
    trust_bundle_evaluation_status: string | undefined | null;
    brand_registration_status: string | undefined | null;
    brand_score: string | number | undefined | null;
    business_registration_number: string | undefined | null;
    authorized_representative_1_job_position: string | undefined | null;
    authorized_representative_1_last_name: string | undefined | null;
    authorized_representative_1_phone_number: string | undefined | null;
    authorized_representative_1_first_name: string | undefined | null;
    authorized_representative_1_email: string | undefined | null;
    authorized_representative_1_business_title: string | undefined | null;
    authorized_representative_2_job_position: string | undefined | null;
    authorized_representative_2_last_name: string | undefined | null;
    authorized_representative_2_phone_number: string | undefined | null;
    authorized_representative_2_first_name: string | undefined | null;
    authorized_representative_2_email: string | undefined | null;
    authorized_representative_2_business_title: string | undefined | null;
    company_type: string | undefined | null;
    stock_exchange: string | undefined | null;
    stock_ticker: string | undefined | null;
}
export interface ICompaignInformation {
    status: string | undefined | null;
    description: string | undefined | null;
    message_sample_1: string | undefined | null;
    message_sample_2: string | undefined | null;
    us_app_to_person_usecase: string | undefined | null;
    us_app_to_person_usecases: any | undefined | null;
    has_embeded_phone: boolean | undefined | null;
    has_embeded_links: boolean | undefined | null;
    opt_out_keywords: string | undefined | null;
    opt_out_message: string | undefined | null;
    opt_in_keywords: string | undefined | null;
    opt_in_message: string | undefined | null;
    help_keywords: string | undefined | null;
    help_message: string | undefined | null;
    message_flow: string | undefined | null;
}

export interface IPersonUsecase {
    label: string;
    value: string;
    description: string;
}

export interface IISVReducer {
    basicInformation: IBasicInformation;
    campaignInformation: ICompaignInformation;
    personUseCase: IPersonUsecase[];
    tollfreeNumberUseCase: ITollfreeNumber;
    isLoading: boolean;
}

export interface ITollfreeNumber {
    business_name: string | undefined | null;
    corporate_website: string | undefined | null;
    business_addr1: string | undefined | null;
    business_addr2: string | undefined | null;
    city: string | undefined | null;
    state: string | undefined | null;
    zip: string | undefined | null;
    business_contact_first_name: string | undefined | null;
    business_contact_last_name: string | undefined | null;
    business_contact_email: string | undefined | null;
    business_contact_phone_number: string | undefined | null;
    message_volume: string | undefined | null;
    tollfree_number: string | undefined | null;
    use_case_category: string | undefined | null;
    use_case_summary: string | undefined | null;
    message_content: string | undefined | null;
    opt_in_workflow_description: string | undefined | null;
    opt_in_image_urls: string | undefined | null;
    business_country: string | undefined | null;
}

const initialState: IISVReducer = {
    basicInformation: {
        organization_name: undefined,
        email: undefined,
        phone_number: undefined,
        street_secondary: undefined,
        street: undefined,
        city: undefined,
        region: undefined,
        postal_code: undefined,
        iso_country: undefined,
        business_name: undefined,
        social_media_profile_urls: undefined,
        website_url: undefined,
        business_regions_of_operation: undefined,
        business_type: undefined,
        business_registration_identifier: undefined,
        business_identity: 'business_identity',
        business_industry: undefined,
        business_registration_number: undefined,
        authorized_representative_1_job_position: undefined,
        authorized_representative_1_last_name: undefined,
        authorized_representative_1_phone_number: undefined,
        authorized_representative_1_first_name: undefined,
        authorized_representative_1_email: undefined,
        authorized_representative_1_business_title: undefined,
        authorized_representative_2_job_position: undefined,
        authorized_representative_2_last_name: undefined,
        authorized_representative_2_phone_number: undefined,
        authorized_representative_2_first_name: undefined,
        authorized_representative_2_email: undefined,
        authorized_representative_2_business_title: undefined,
        company_type: undefined,
        stock_exchange: undefined,
        stock_ticker: undefined,
        brand_error: undefined,
        brand_feedback: undefined,
        brand_identity_status: undefined,
        trust_bundle_status: undefined,
        brand_registration_status: undefined,
        trust_bundle_evaluation_status: undefined,
        brand_score: undefined,
    },
    campaignInformation: {
        status: undefined,
        description: undefined,
        message_sample_1: undefined,
        message_sample_2: undefined,
        us_app_to_person_usecase: undefined,
        us_app_to_person_usecases: undefined,
        has_embeded_phone: false,
        has_embeded_links: false,
        opt_out_keywords: 'CANCEL,END,QUIT,UNSUBSCRIBE,STOP,STOPALL',
        opt_out_message:
            'You have successfully been unsubscribed. You will not receive any more messages from this number. Reply START to resubscribe.',
        opt_in_keywords: 'SUBSCRIBE,START',
        opt_in_message:
            'Welcome to (Company Name). You will start receiving text messages. If you need assistance, text HELP in reply to this message. Reply STOP to cancel subscription.',
        help_keywords: 'HELP,INFO',
        help_message: 'Reply STOP to unsubscribe. Msg&Data Rates May Apply.',
        message_flow: undefined,
    },
    personUseCase: [],
    tollfreeNumberUseCase: {
        business_name: undefined,
        corporate_website: undefined,
        business_addr1: undefined,
        business_addr2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        business_contact_first_name: undefined,
        business_contact_last_name: undefined,
        business_contact_email: undefined,
        business_contact_phone_number: undefined,
        message_volume: undefined,
        tollfree_number: undefined,
        use_case_category: undefined,
        use_case_summary: undefined,
        message_content: undefined,
        opt_in_workflow_description: undefined,
        opt_in_image_urls: undefined,
        business_country: undefined,
    },
    isLoading: false,
};

export const isv = createReducer<IISVReducer>({}, initialState);

isv.on(changeBasicInformationFieldValue, (state: IISVReducer, payload: { key: string; value: string }) => ({
    ...state,
    basicInformation: {
        ...state.basicInformation,
        [payload.key]: payload.value,
    },
}));
isv.on(changeCampaignInformationFieldValue, (state: IISVReducer, payload: { key: string; value: string }) => ({
    ...state,
    campaignInformation: {
        ...state.campaignInformation,
        [payload.key]: payload.value,
    },
}));
isv.on(changeTollfreeInformationFieldValue, (state: IISVReducer, payload: { key: string; value: string }) => ({
    ...state,
    tollfreeNumberUseCase: {
        ...state.tollfreeNumberUseCase,
        [payload.key]: payload.value,
    },
}));
isv.on(toggleISVInfoLoader, (state: IISVReducer, payload: boolean) => ({
    ...state,
    isLoading: payload,
}));

isv.on(setBasicInformation, (state: IISVReducer, payload: any) => ({
    ...state,
    basicInformation: payload,
}));
isv.on(setCampaignInformation, (state: IISVReducer, payload: any) => ({
    ...state,
    campaignInformation: payload,
}));
isv.on(setTollfreeInformation, (state: IISVReducer, payload: any) => ({
    ...state,
    tollfreeNumberUseCase: payload,
}));

isv.on(signOutUser, () => initialState);
