import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: 'AIzaSyBWvFdCrwq6-yLWxvJdOMGcoWr5pXTWREE',
    authDomain: 'trutext.firebaseapp.com',
    projectId: 'trutext',
    storageBucket: 'trutext.appspot.com',
    messagingSenderId: '614832320186',
    appId: '1:614832320186:web:9271903203078d133d1c83',
    measurementId: 'G-8GWS6MYNS5',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: 'BJqFnESCUin_q7M5b8eELX0upEARLhdvbGqISL6um6xzGVzNq6V9lhKgusXu3KjpP8HVzD1LjxLu2B8eue2fIZ8',
    });
};

export default firebaseApp;
