import { IStoreState } from "../types";

export const getISVBasicInformation = (state: IStoreState) =>
    state.isv.basicInformation || {};

export const getISVCampaignInformation = (state: IStoreState) =>
    state.isv.campaignInformation || {};


export const getTollFreeInformation = (state: IStoreState) =>
    state.isv.tollfreeNumberUseCase || {};

export const getISVFormLoading = (state: IStoreState) =>
    state.isv.isLoading || false;
