import { ISagaAction } from '../types';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import * as actions from '../actions';
import { buyNewNumbersAPI, getAvailableNewNumber } from '../utils/Apis/buy.numbers';
import { showAlert } from '../utils/AlertUtils';

export const getAllAvailableNumbers = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const result = yield call(getAvailableNewNumber, action.payload);
        yield put(actions.setNumbers(result.data.data));
        yield put(actions.toggleLoader(false));
    } catch (e) {
        console.log(e);
        yield put(actions.toggleLoader(false));
    }
};

export const buyNewNumber = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { number, navigate } = action.payload;
        if (!number) {
            const data = {
                title: 'Error!',
                text: 'Please select phone number.',
                icon: 'warning',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        } else {
            yield put(actions.toggleLoader(true));
            const result = yield call(buyNewNumbersAPI, number);
            console.log(result);
            yield put(actions.toggleLoader(false));
            yield put(actions.fetchProfile());
            if (navigate) {
                navigate('/');
            }
        }
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewAddressErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
        yield put(actions.toggleLoader(false));
    }
};
