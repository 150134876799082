import { ISagaAction } from '../types';
import { SagaIterator } from 'redux-saga';
import { put, fork, select, call, take, cancelled, cancel } from 'redux-saga/effects';
import * as actions from '../actions';
import { emitSocketEvent, getSocketEvents } from '../utils/Apis/message';
import { ENV_BASE_URL } from '../utils/apiConstants';
import {
    getConverstations,
    getCurrentUser,
    getHostedNumberDetails,
    getMessageSelectedPhoneNumber,
    getEnvironment,
    getMMSUrlIds,
    getBrowserId,
} from '../selectors';
import { IConverstaion, IMMSUrls } from '../reducers';
import { deActivateSocket, refreshApp } from '../utils/socket';
import moment from 'moment-timezone';
import { showAlert } from '../utils/AlertUtils';
import {
    addFCMToken, getDeviceId,
    getHostedNumberAPI,
    getToken,
    requestUserPermission,
    setHostedNumberAPI,
    uploadFileAPI,
} from '../utils/Apis/buy.numbers';


export const fetchAllConversation = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);
        const baseUrl = ENV_BASE_URL[environment];
        const user = yield select(getCurrentUser);
        yield call(emitSocketEvent, baseUrl, user?.token, 'conversations', {});
    } catch (e) {
        console.log(e);
    }
};
/*

export const onActivateSocket = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);
        const user = yield select(getCurrentUser);
        const baseUrl = ENV_BASE_URL[environment];
        yield call(emitSocketEvent, baseUrl, 'token', { token: user?.token });
    } catch (e) {
        console.log(e);
    }
};
*/

export const doDeActivateSocket = function* (): SagaIterator {
    try {
        yield call(deActivateSocket);
        yield call(refreshApp);
    } catch (e) {
        console.log(e);
    }
};
export const sendNewMessage = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { phoneNumber, message } = action.payload;
        const mmsUrlIds: IMMSUrls[] = yield select(getMMSUrlIds);
        const environment = yield select(getEnvironment);
        const baseUrl = ENV_BASE_URL[environment];
        const user = yield select(getCurrentUser);
        yield call(emitSocketEvent, baseUrl, user?.token, 'send-messages', {
            phoneNumber,
            message,
            mmsUrls: mmsUrlIds.map((x) => x.id),
        });
        yield put(actions.typeMessage(''));
        yield put(actions.setMMSURLIds([]));
    } catch (e) {
        console.log(e);
    }
};

export const fetchAllMessage = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { phoneNumber, pageNo } = action.payload;
        const environment = yield select(getEnvironment);
        const baseUrl = ENV_BASE_URL[environment];
        const user = yield select(getCurrentUser);
        if (pageNo === 1) {
            yield put(actions.setMessages([]));
        }
        yield call(emitSocketEvent, baseUrl, user?.token, 'messages', { phoneNumber, pageNo });
    } catch (e) {
        console.log(e);
    }
};

export const messageFetchSaga = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(messageFetchService);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const messageFetchService = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);

        const user = yield select(getCurrentUser);
        const channel = yield call(getSocketEvents, ENV_BASE_URL[environment], user?.token, 'messages');
        try {
            while (true) {
                const result = yield take(channel);
                // const currentPage = yield select(getsMessagesPageNumber);
                yield put(actions.setHasMessages(result.length !== 0));
                // if (currentPage === 1) {
                //     yield put(actions.setMessages(result));
                // } else {
                yield put(actions.appendMessages(result));
                // }
            }
        } catch (e) {
            console.log(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const converstationFetchSaga = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(conversationFetchService);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const conversationFetchService = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);

        const user = yield select(getCurrentUser);
        const channel = yield call(getSocketEvents, ENV_BASE_URL[environment], user?.token, 'conversations');
        try {
            while (true) {
                const result = yield take(channel);
                yield put(actions.setAllConversation(result));
            }
        } catch (e) {
            console.log(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const newMessageFetchSaga = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(newMessageFetchService);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const newMessageFetchService = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);

        const user = yield select(getCurrentUser);
        const channel = yield call(getSocketEvents, ENV_BASE_URL[environment], user?.token, 'new-message');
        try {
            while (true) {
                const result = yield take(channel);
                const allConversation: IConverstaion[] = yield select(getConverstations);
                const currentSelectedChat = yield select(getMessageSelectedPhoneNumber);
                if (
                    currentSelectedChat === result.to_phone_number ||
                    currentSelectedChat === result.from_phone_number
                ) {
                    yield put(actions.addMessages(result));
                }
                const existConversationIndex = allConversation.findIndex(
                    (x) => x.phone_number === result.to_phone_number || x.phone_number === result.from_phone_number,
                );
                const newCopy = allConversation.map((x) => x);
                if (existConversationIndex !== -1) {
                    newCopy[existConversationIndex] = {
                        ...newCopy[existConversationIndex],
                        last_message_at: result.created_at || moment().toDate(),
                        last_message_status: result.status,
                        last_message: result.text_message,
                    };
                } else {
                    newCopy.push({
                        phone_number: result.phone_number,
                        id: 0,
                        first_name: '',
                        last_name: '',
                        last_message_at: result.created_at || moment().toDate(),
                        last_message_status: result.status,
                        last_message: result.text_message,
                    });
                }
                yield put(actions.setAllConversation(newCopy));
            }
        } catch (e) {
            console.log(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const updateMessageFetchSaga = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(updateMessageFetchService);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const updateMessageFetchService = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);

        const user = yield select(getCurrentUser);
        const channel = yield call(getSocketEvents, ENV_BASE_URL[environment], user?.token, 'update-message');
        try {
            while (true) {
                const result = yield take(channel);
                const currentSelectedChat = yield select(getMessageSelectedPhoneNumber);
                if (
                    currentSelectedChat === result.to_phone_number ||
                    currentSelectedChat === result.from_phone_number
                ) {
                    yield put(actions.updateMessages(result));
                }
                yield put(actions.fetchAllConversation());
            }
        } catch (e) {
            console.log(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const errorMessageFetchSaga = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(errorMessageFetchService);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const errorMessageFetchService = function* (): SagaIterator {
    try {
        const environment = yield select(getEnvironment);

        const user = yield select(getCurrentUser);
        const channel = yield call(getSocketEvents, ENV_BASE_URL[environment], user?.token, 'error');
        try {
            while (true) {
                const result = yield take(channel);
                const data = {
                    title: 'Error!',
                    text: result?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
                yield put(actions.getRoutes());
            }
        } catch (e) {
            console.log(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const getHostedNumber = function* (): SagaIterator {
    try {
        yield put(actions.toggleHostedNumberLoader(true));
        const result = yield call(getHostedNumberAPI);
        yield put(actions.updateHostedNumberData(result.data.data));
        yield put(actions.toggleHostedNumberLoader(false));
    } catch (e) {
        console.log(e);
        yield put(actions.toggleHostedNumberLoader(false));
    }
};

export const setHostedNumber = function* (): SagaIterator {
    try {
        yield put(actions.toggleHostedNumberLoader(true));
        const hostedNumberDetails = yield select(getHostedNumberDetails);
        const result = yield call(setHostedNumberAPI, hostedNumberDetails);
        yield put(actions.updateHostedNumberData(result.data.data));
        yield put(actions.toggleHostedNumberLoader(false));
        const data = {
            title: 'Success!',
            text: "Hosted number data updated!",
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        yield put(actions.toggleHostedNumberLoader(false));
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewAddressErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};

export const uploadImage = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.toggleHostedNumberLoader(false));
        const file = action.payload;
        const result = yield call(uploadFileAPI, file);
        console.log(result);
        yield put(actions.setMMSURLIds(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};

export const notificationMessageService = function* (): SagaIterator {
    while (yield take(actions.activateSocket)) {
        const connectionTask = yield fork(requestNotification);
        // wait for the user stop action
        yield take([actions.deActivateSocket, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};
export const requestNotification = function* (): SagaIterator {
    try {
        const permissionEnabled = yield call(requestUserPermission);
        yield put(actions.setNotificationEnable(permissionEnabled === "granted"));
        const token = yield call(getToken);
        let uniqueId = yield select(getBrowserId);
        if (!uniqueId) {
            uniqueId = yield call(getDeviceId);
            yield put(actions.setBrowserId(uniqueId));
        }
        yield put(actions.setFcmToken(token));
        const data = {
            device_id: uniqueId,
            device_type: 'web',
            reg_id: token,
        };
        yield call(addFCMToken, data);
        /*const channel = yield call(onNotificationMessage);
        try {
            while (true) {
                const result = yield take(channel);
                console.log("Result :: ", result);
                yield put(actions.fetchAllConversation());
            }
        } catch (e) {
            console.log('Error ehere === ', e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }*/
    } catch (e) {
        console.log('asd == ', e);
    }
};
