import { IStoreState } from "../types";

export const getConverstations = (state: IStoreState) =>
    state.messages.conversations;

export const getMessages = (state: IStoreState) =>
    state.messages.messages;

export const getMessageSelectedPhoneNumber = (state: IStoreState) =>
    state.messages.selectedPhoneNumber;

export const getsMessagesPageNumber = (state: IStoreState) =>
    state.messages.pageNo;

export const getCurrentTypedMessage = (state: IStoreState) =>
    state.messages.typedMessage;

export const getsMessagesLoader = (state: IStoreState) =>
    state.messages.isLoading;


export const hasMessages = (state: IStoreState) =>
    state.messages.hasMessages;

export const getHostedNumberDetails = (state: IStoreState) =>
    state.messages.hostedNumber || {};


export const getHostedNumberError = (state: IStoreState) =>
    state.messages.hostedNumberError;

export const getMMSUrlIds = (state: IStoreState) =>
    state.messages.mmsURLIds;
