import { IStoreState } from "../types";

export const isSidemenuOpen = (state: IStoreState) =>
    state.settings.showSidebar;

export const sidemenuHoverState = (state: IStoreState) =>
    state.settings.sidebarHoverState;

export const isTemplateModalOpen = (state: IStoreState) =>
    state.settings.showTemplateModal;


export const isAddressModalOpen = (state: IStoreState) =>
    state.settings.showAddressModal;
export const isNewNumberModalOpen = (state: IStoreState) =>
    state.settings.showNewNumberModal;

export const isImportAddressModalOpen = (state: IStoreState) =>
    state.settings.showImportAddressModal;

export const isAddNewAddressModalOpen = (state: IStoreState) =>
    state.settings.showAddNewAddressModal;

export const isAddNewUserModalOpen = (state: IStoreState) =>
    state.settings.showAddNewUserModal;

export const isAddNewTemplateModalOpen = (state: IStoreState) =>
    state.settings.showAddNewTemplateModal;

export const isAddNewKeywordResponderModalOpen = (state: IStoreState) =>
    state.settings.showAddNewKeywordResponderModal;

export const isAddNewScheduleResponderModalOpen = (state: IStoreState) =>
    state.settings.showAddNewScheduleResponderModal;

export const isAddNewAdvanceResponderModalOpen = (state: IStoreState) =>
    state.settings.showAddNewAdvanceResponderModal;

export const isViewBroadcastModalOpen = (state: IStoreState) =>
    state.settings.showViewBroadcastModal;

export const isPreDraftMessageModalOpen = (state: IStoreState) =>
    state.settings.showPredraftMessageModal;

export const isPlanChooserModalOpen = (state: IStoreState) =>
    state.settings.showPlanChooserModal;
export const isAddCreditModalOpen = (state: IStoreState) =>
    state.settings.showAddCreditModal;
