import { createReducer } from 'redux-act';
import {
    resetNewResponderForm,
    signOutUser, toggleAddCreditsModal,
    toggleAddNewAddressModal, toggleAddNewAdvanceResponderModal,
    toggleAddNewKeywordResponderModal,
    toggleAddNewScheduleResponderModal,
    toggleAddNewTemplateModal, toggleAddressModal, toggleAddUserModal,
    toggleImportAddressModal, toggleNewNumberModal, togglePlanchooserModal, togglePredraftMessageModal,
    toggleSideMenu,
    toggleSideMenuHover,
    toggleTemplateModal, toggleViewBroadcastModal
} from '../actions';

export interface ISettingsReducer {
    showSidebar: boolean;
    sidebarHoverState: boolean;
    showTemplateModal: boolean
    showAddressModal: boolean
    showNewNumberModal: boolean
    showImportAddressModal: boolean
    showAddNewAddressModal: boolean
    showAddNewUserModal: boolean
    showAddNewTemplateModal: boolean
    showAddNewKeywordResponderModal: boolean
    showAddNewScheduleResponderModal: boolean
    showAddNewAdvanceResponderModal: boolean
    showViewBroadcastModal: boolean
    showPredraftMessageModal: boolean
    showPlanChooserModal: boolean
    showAddCreditModal: boolean
}

const initialState = {
    showSidebar: false,
    sidebarHoverState: false,
    showTemplateModal: false,
    showAddressModal: false,
    showNewNumberModal: false,
    showImportAddressModal: false,
    showAddNewAddressModal: false,
    showAddNewUserModal: false,
    showAddNewTemplateModal: false,
    showAddNewKeywordResponderModal: false,
    showAddNewScheduleResponderModal: false,
    showAddNewAdvanceResponderModal: false,
    showViewBroadcastModal: false,
    showPredraftMessageModal: false,
    showPlanChooserModal: false,
    showAddCreditModal: false,
};

export const settings = createReducer<ISettingsReducer>({}, initialState);
settings.on(toggleSideMenu, (state: ISettingsReducer) => ({
    ...state,
    showSidebar: !state.showSidebar,
}));
settings.on(toggleSideMenuHover, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    sidebarHoverState: payload,
}));

settings.on(toggleTemplateModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showTemplateModal: payload,
}));

settings.on(toggleAddressModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddressModal: payload,
}));

settings.on(toggleNewNumberModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showNewNumberModal: payload,
}));


settings.on(toggleImportAddressModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showImportAddressModal: payload,
}));


settings.on(toggleAddNewAddressModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewAddressModal: payload,
}));

settings.on(toggleAddNewTemplateModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewTemplateModal: payload,
}));
settings.on(toggleAddNewKeywordResponderModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewKeywordResponderModal: payload,
}));

settings.on(toggleAddNewScheduleResponderModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewScheduleResponderModal: payload,
}));

settings.on(toggleAddNewAdvanceResponderModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewAdvanceResponderModal: payload,
}));
settings.on(toggleViewBroadcastModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showViewBroadcastModal: payload,
}));
settings.on(togglePredraftMessageModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showPredraftMessageModal: payload,
}));
settings.on(togglePlanchooserModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showPlanChooserModal: payload,
}));
settings.on(toggleAddCreditsModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddCreditModal: payload,
}));
settings.on(toggleAddUserModal, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    showAddNewUserModal: payload,
}));
settings.on(resetNewResponderForm, (state: ISettingsReducer) => ({
    ...state,
    showAddNewKeywordResponderModal: false,
    showAddNewScheduleResponderModal: false,
    showAddNewAdvanceResponderModal: false,
}));

settings.on(signOutUser, () => initialState);
