import React from "react";

const AccessDeniedConfig = {
  routes: [
    {
      path: "/access-denied",
      component: React.lazy(() => import("./AccessDenied"))
    }
  ]
};
export default AccessDeniedConfig
