import { createReducer } from 'redux-act';
import {
    setAllPlans,
    setCredits,
    setLastInvoice,
    setPrefetchedCardData,
    signOutUser,
    toggleStripeLoader
} from '../actions';
interface IPlan {
    features: string[]
    id: number;
    image_location: string;
    is_popular: boolean;
    is_current_plan: boolean;
    is_free_trial: boolean;
    expiry_date: string | null;
    start_date: string | null;
    change_allowed: boolean;
    plan_name: string;
    description: string;
    plan_price: string;
    status: string;
}
interface IInvoice {
    invoice: any;
    last_transaction: {
        amount: number;
        type_of_transaction: string;
        transaction_description: string;
        transaction_id: string;
        start_date: string;
        end_date: string;
        status: string;
        is_subscription: boolean;
        created_by: number;
    }
}
export interface IStripeReducer {
    cardDetails?: any;
    allPlans: IPlan[];
    isLoading: boolean;
    lastInvoice?: IInvoice;
    credits: number;
}

const initialState = {
    credits: 0,
    isLoading: false,
    allPlans: [],
};

export const stripe = createReducer<IStripeReducer>({}, initialState);
stripe.on(setCredits, (state: IStripeReducer, payload: number) => ({
    ...state,
    credits: payload,
}));
stripe.on(setPrefetchedCardData, (state: IStripeReducer, payload: any) => ({
    ...state,
    cardDetails: payload,
}));
stripe.on(setAllPlans, (state: IStripeReducer, payload: any) => ({
    ...state,
    allPlans: payload,
}));
stripe.on(setLastInvoice, (state: IStripeReducer, payload: any) => ({
    ...state,
    lastInvoice: payload,
}));
stripe.on(toggleStripeLoader, (state: IStripeReducer, payload: boolean) => ({
    ...state,
    isLoading: payload,
}));
stripe.on(signOutUser, () => initialState);
