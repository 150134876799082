import { createAction } from 'redux-act';
import { Addresses } from '../reducers';

export const fetchAddress =
    createAction<{ type?: string; filterBy?: string | null ; searchText?: string }>('get all address');
export const mergeAllAddress = createAction<Addresses[]>('put all address');
export const setTotalAddressRecords = createAction<number>('set total address records ');
export const setAddress = createAction<Addresses[]>('set address');
export const changeAddressCurrentPage = createAction<number>('set address current page');
export const changeAddressPerPage = createAction<number>('set address per page');
export const importAddress = createAction<any>('import address from csv');
export const createNewAddress = createAction<any>('create new addresses');
export const changeAddressStatus = createAction<any>('change address status');

export const changeNewAddressFieldValue = createAction<any>('change address field value');
export const setNewAddressErrorValue = createAction<any>('set error values');
export const setNewAddressValue = createAction<any>('set address values');
export const resetNewAddressForm = createAction('address reset form');
export const fetchExistingTags = createAction('get existing address tags');
export const setExistingAddressTags = createAction<string[]>('set existing address tags');
