import React from 'react';

const AddressConfig = {
    routes: [
        {
            path: '/address-book/:type',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./AddressScreen')),
        },
    ],
};
export default AddressConfig;
