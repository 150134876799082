import axios from 'axios';
import { APIUrl } from '../apiConstants';

export const getTemplatesAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.templates}?page=${payload.page || 1}&limit=${payload.limit}`, {
        headers: headers,
        timeout: 10000,
    });
};

export const createTemplateAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.templates}`, payload, {
        headers: headers,
        timeout: 10000,
    });
};

export const editTemplateAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.patch(`${APIUrl.templates}${payload.id}`, payload, {
        headers: headers,
        timeout: 10000,
    });
};
export const deleteTemplateAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.delete(`${APIUrl.templates}${payload.id}`, {
        headers: headers,
        timeout: 10000,
    });
};

export const getBroadcastAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.broadcast}/?page=${payload.page || 1}&limit=${payload.limit}`, {
        headers: headers,
        timeout: 10000,
    });
};

export const createBroadcastAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.broadcast}/`, payload, {
        headers: headers,
        timeout: 10000,
    });
};

export const editBroadcastAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.patch(`${APIUrl.broadcast}/${payload.id}`, payload, {
        headers: headers,
        timeout: 10000,
    });
};
export const deleteBroadcastAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.delete(`${APIUrl.broadcast}/${payload.id}`, {
        headers: headers,
        timeout: 10000,
    });
};

export const getResponderAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.autoResponder}/?page=${payload.page || 1}&limit=${payload.limit}&type=${payload.type}`, {
        headers: headers,
        timeout: 10000,
    });
};

export const createResponderAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.autoResponder}/`, payload, {
        headers: headers,
        timeout: 10000,
    });
};

export const editResponderAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const fpayload = Object.keys(payload)
        .filter((k) => payload[k] != null)
        .reduce((a, k) => ({ ...a, [k]: payload[k] }), {});

    return axios.patch(`${APIUrl.autoResponder}/${payload.id}`, fpayload, {
        headers: headers,
        timeout: 10000,
    });
};
export const deleteResponderAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.delete(`${APIUrl.autoResponder}/${payload.id}`, {
        headers: headers,
        timeout: 10000,
    });
};
