import { createAction } from "redux-act";

export const toggleSideMenu = createAction('toggle side menu');
export const toggleSideMenuHover = createAction<boolean>('toggle side menu hover');
export const toggleTemplateModal = createAction<boolean>('toggle template modal');
export const toggleAddressModal = createAction<boolean>('toggle address modal');
export const toggleNewNumberModal = createAction<boolean>('toggle new number modal');
export const toggleImportAddressModal = createAction<boolean>('toggle import address modal');
export const toggleAddNewAddressModal = createAction<boolean>('toggle add new address modal');
export const toggleAddNewTemplateModal = createAction<boolean>('toggle add new template modal');
export const toggleAddNewKeywordResponderModal = createAction<boolean>('toggle add new Keyword Responder modal');
export const toggleAddNewScheduleResponderModal = createAction<boolean>('toggle add new Schedule Responder modal');
export const toggleAddNewAdvanceResponderModal = createAction<boolean>('toggle add new advance Responder modal');
export const toggleViewBroadcastModal = createAction<boolean>('toggle view broadcast modal');
export const togglePredraftMessageModal = createAction<boolean>('toggle view predraft message modal');
export const togglePlanchooserModal = createAction<boolean>('toggle plan chooser modal');
export const toggleAddCreditsModal = createAction<boolean>('toggle add credit modal');
export const toggleAddUserModal = createAction<boolean>('toggle add user modal');
