import { createReducer } from 'redux-act';
import {
    fetchAllConversation,
    setSelectedPhoneNumber,
    fetchMessages,
    setAllConversation,
    setMessages,
    signOutUser,
    typeMessage,
    addMessages,
    appendMessages,
    setHasMessages,
    updateMessages,
    changeHostedNumberData,
    updateHostedNumberData,
    toggleHostedNumberLoader,
    updateHostedNumberError,
    setMMSURLIds, toggleChatLoader, setNotificationEnable, setFcmToken,
} from '../actions';

export interface IHostedNumber {
    name?: string;
    street?: string;
    region?: string;
    phone_number?: string;
    is_tollfree?: boolean;
    city?: string;
    country?: string;
    status?: string;
    postal_code?: string;
}

export interface IMessages {
    text_message?: string;
    to_phone_number?: string;
    from_phone_number: string;
    sid?: string | null;
    status: string;
    error_code?: string;
    error_message?: string;
    sms_time?: string;
    created_by?: {
        id?: number;
        first_name?: string;
        last_name?: string;
    };
    created_at?: string;
    updated_at?: string;
    mms: IMMSUrls[]
}

export interface IConverstaion {
    phone_number: string;
    id?: number;
    first_name?: string;
    last_name?: string;
    last_message_at: string;
    status?: string;
    last_message: string;
    last_message_status?: string;
    is_temp?: boolean;
}

export interface IMMSUrls {
    id: number;
    file_path: string;
    bucket: string;
    file_name: string;
}

export interface IMessageReducer {
    messages: IMessages[];
    hostedNumber: IHostedNumber | null;
    hostedNumberError?: any;
    conversations: IConverstaion[];
    notificationEnabled: boolean;
    fcmToken: string;
    mmsURLIds: IMMSUrls[];
    selectedPhoneNumber: string;
    typedMessage: string;
    pageNo: number;
    hasMessages: boolean;
    isLoading: boolean;
    isHostedNumberLoading: boolean;
}

const initialState = {
    messages: [],
    conversations: [],
    mmsURLIds: [],
    hostedNumber: null,
    selectedPhoneNumber: '',
    typedMessage: '',
    pageNo: 1,
    isLoading: false,
    hasMessages: true,
    isHostedNumberLoading: false,
    notificationEnabled: false,
    fcmToken: '',
};

export const messages = createReducer<IMessageReducer>({}, initialState);

messages.on(fetchAllConversation, (state: IMessageReducer) => ({
    ...state,
    isLoading: true,
}));
messages.on(setAllConversation, (state: IMessageReducer, payload: IConverstaion[]) => ({
    ...state,
    conversations: payload,
    isLoading: false,
}));

messages.on(setSelectedPhoneNumber, (state: IMessageReducer, payload: { phoneNumber: string }) => ({
    ...state,
    selectedPhoneNumber: payload.phoneNumber,
    messages: [],
    conversations: state.conversations.some((x) => x.phone_number === payload.phoneNumber && x.is_temp === undefined)
        ? state.conversations.filter((x) => x.is_temp !== true)
        : state.conversations,
}));
messages.on(fetchMessages, (state: IMessageReducer, payload: { pageNo: number }) => ({
    ...state,
    isLoading: true,
    pageNo: payload.pageNo,
    hasMessages: payload.pageNo === 1 ? true : state.hasMessages,
}));
messages.on(setHasMessages, (state: IMessageReducer, payload: boolean) => ({
    ...state,
    hasMessages: payload,
    pageNo: state.pageNo - (!payload ? 1 : 0),
}));

messages.on(setMessages, (state: IMessageReducer, payload: IMessages[]) => ({
    ...state,
    messages: payload,
    isLoading: false,
}));

messages.on(appendMessages, (state: IMessageReducer, payload: IMessages[]) => ({
    ...state,
    messages: [...state.messages, ...payload],
    isLoading: false,
}));

messages.on(addMessages, (state: IMessageReducer, payload: IMessages) => ({
    ...state,
    messages: [...state.messages, payload],
    isLoading: false,
}));

messages.on(updateMessages, (state: IMessageReducer, payload: IMessages) => {
    const oldMessages = [...state.messages];
    const index = oldMessages.findIndex((x) => x.sid === payload.sid);
    if (index !== -1) {
        oldMessages[index] = payload;
    }
    return { ...state, messages: oldMessages, isLoading: false };
});

messages.on(typeMessage, (state: IMessageReducer, payload: string) => ({
    ...state,
    typedMessage: payload,
}));
messages.on(changeHostedNumberData, (state: IMessageReducer, payload: { key: string; value: string }) => ({
    ...state,
    hostedNumber: { ...state.hostedNumber, [payload.key]: payload.value },
    hostedNumberError: null,
}));
messages.on(updateHostedNumberData, (state: IMessageReducer, payload: any) => ({
    ...state,
    hostedNumber: payload,
    hostedNumberError: null,
}));
messages.on(updateHostedNumberError, (state: IMessageReducer, payload: any) => ({
    ...state,
    hostedNumberError: payload,
}));

messages.on(toggleHostedNumberLoader, (state: IMessageReducer, payload: boolean) => ({
    ...state,
    isHostedNumberLoading: payload,
}));

messages.on(setMMSURLIds, (state: IMessageReducer, payload: IMMSUrls[]) => ({
    ...state,
    mmsURLIds: payload,
}));

messages.on(toggleChatLoader, (state: IMessageReducer, payload: boolean) => ({
    ...state,
    isLoading: payload,
}));
messages.on(setNotificationEnable, (state: IMessageReducer, payload: boolean) => ({
    ...state,
    notificationEnabled: payload,
}));

messages.on(setFcmToken, (state: IMessageReducer, payload: string) => ({
    ...state,
    fcmToken: payload,
}));


messages.on(signOutUser, () => initialState);
