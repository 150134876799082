import React from 'react';

const AnalyticsConfigs = {
    routes: [
        {
            path: '/analytics/micro-url',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./MicroUrl/MicroUrlPage')),
        },
        {
            path: '/analytics/link-tracker',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./LinkTracker/LinkTrackerPage')),
        },
        {
            path: '/analytics/reporting',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./Reporting/ReportingPage')),
        },
    ],
};
export default AnalyticsConfigs;
