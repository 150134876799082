import { SagaIterator } from 'redux-saga/index';
import { ISagaAction } from '../types';
import { select, put, call } from 'redux-saga/effects';
import {
    autoLoginAPI,
    forgotPasswordApi,
    getRoutesAPI,
    getSubUsers,
    getUserProfile,
    resetPasswordApi,
    signInApi,
    signUpApi,
    updateUserProfile,
    verifyAccountApi,
    createSubUserAPI
} from '../utils/Apis/user';
import * as actions from '../actions';
import { showAlert } from '../utils/AlertUtils';
import { getNewUserPerPage, getIsUserAuth, getNewUserCurrentPage } from '../selectors';

export const signIn = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { email, password } = action.payload;
        const result = yield call(signInApi, { email, password });
        yield put(actions.setUserData(result.data.data));
        yield put(actions.getRoutes());
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};

export const getProfile = function* (): SagaIterator {
    try {
        const result = yield call(getUserProfile);
        yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};

export const updateProfile = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { firstName, lastName, centerName, website, location, businessPhoneNumber } = action.payload;
        const result = yield call(updateUserProfile, {
            first_name: firstName,
            last_name: lastName,
            center_name: centerName,
            phone_number: `${businessPhoneNumber}`,
            website,
            location,
        });
        yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};

export const userAutoLogin = function* (action: ISagaAction<any>): SagaIterator {
    const { code, navigation } = action.payload;
    try {
        const isAuth = yield select(getIsUserAuth);
        if (isAuth) {
            yield put(actions.signOutUser());
        }
        const result = yield call(autoLoginAPI, { code });
        yield put(actions.setUserData(result.data.data));
        yield put(actions.getRoutes());
        if (navigation) {
            navigation('/');
        }
    } catch (e: any) {
        console.log(e);
        navigation('/');
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const getRoutes = function* (): SagaIterator {
    try {
        const result = yield call(getRoutesAPI);
        yield put(actions.setRoutes(result.data.data.data.navigation_links));
        yield put(actions.setComponentRoutes(result.data.data.data.routes));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};

export const resetPasswordSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { code, newPassword, navigation } = action.payload;
        yield call(resetPasswordApi, { code, new_password: newPassword });
        const data = {
            title: 'Sucess!',
            text: 'Your password has successfully updated.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        if (navigation) {
            navigation('/');
        }
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const verifyAccountSaga = function* (action: ISagaAction<any>): SagaIterator {
    const { code, navigation } = action.payload;
    try {
        const result = yield call(verifyAccountApi, { code });
        if (result.data.data) {
            yield put(actions.setUserData(result.data.data));
            yield put(actions.getRoutes());
            const data = {
                title: 'Sucess!',
                text: 'Your account has successfully activated.',
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        if (navigation) {
            navigation('/');
        }
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
            if (navigation) {
                navigation('/');
            }
        }
    }
};

export const forgotPasswordSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { email } = action.payload;
        yield call(forgotPasswordApi, { email });
        const data = {
            title: 'Sucess!',
            text: 'Password reset link has successfully sent to your registered email.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};

export const signUp = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setSignupMessage(undefined));
        const { email, password, first_name, last_name, center_name, phone_number, navigation } = action.payload;
        const result = yield call(signUpApi, {
            email,
            password,
            first_name,
            last_name,
            center_name,
            phone_number: Number(phone_number),
            country_code: '+1',
        });
        const message = result.data.message;
        yield put(actions.setSignupMessage({ isError: false, message }));
        const data = {
            title: 'Success!',
            text: 'We have sent an email to verify your account. Please activate your account.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        if (navigation) {
            navigation('/');
        }
        // yield put(authUser());
    } catch (e: any) {
        yield put(actions.setSignupMessage({ isError: true, message: '', errors: e?.response?.data?.errors }));
        if (e !== 'Session expired') {
            console.log(e);
            let message = e?.response?.data?.errors?.email;
            const data = {
                title: 'Error!',
                text: message || e?.response?.data?.errors,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};
export const getAllSubuser = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { filterBy, searchText } = action.payload;
        const page = yield select(getNewUserCurrentPage);
        const limit = yield select(getNewUserPerPage);
        const result = yield call(getSubUsers, { page, limit, filterBy, searchText });
        yield put(actions.setSubUsers(result.data.data.rows));
        console.log("result.data.data.rows ::: ", result.data.data.rows)
        yield put(actions.setTotalSubUserRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};
export const createNewSubuser = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.setNewSubuserErrorValue(null));
        const { id, email, first_name, last_name, password } = action.payload;
        yield call(createSubUserAPI, {
            id,
            email,
            first_name,
            last_name,
            password
        });
        yield put(actions.toggleAddUserModal(false));
        yield put(actions.resetNewSubuserForm());
        yield put(
            actions.fetchSubUsers({ }),
        );
        const data = {
            title: 'Success!',
            text: `User successfully ${id ? 'updated' : 'created'}.`,
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        // yield put(authUser());
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewSubuserErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};
