import React from 'react';

const HostNumberConfig = {
    routes: [
        {
            path: '/settings/host-number',
            private: true,
            hasLayout: true,
            hasHeader: true,
            component: React.lazy(() => import('./HostNumber')),
        },
    ],
};
export default HostNumberConfig;
