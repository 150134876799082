import { createAction } from "redux-act";

export const changeBasicInformationFieldValue = createAction<{key: string, value: string}>('change basic info value');
export const changeCampaignInformationFieldValue = createAction<{key: string, value: string}>('change campaign info value');
export const changeTollfreeInformationFieldValue = createAction<{key: string, value: string}>('change tollfree info value');

export const updateBasicInformation = createAction<{isSubmited: boolean}>('update basic information');
export const updateCampaignInformation = createAction<{isSubmited: boolean}>('update campaign information');
export const updateTollfreeNumberInformation = createAction('update tollfree information');

export const fetchBasicInformation = createAction('fetch basic information');
export const fetchCampaignInformation = createAction('fetch campaign information');
export const fetchTollfreeInformation = createAction('fetch tollfree information');
export const setBasicInformation = createAction<any>('set basic information');
export const setCampaignInformation = createAction<any>('set campaign information');
export const setTollfreeInformation = createAction<any>('set tollfree information');
export const toggleISVInfoLoader = createAction<boolean>('toggle loader');
