import { createAction } from 'redux-act';
import { IConverstaion, IMessages, IMMSUrls } from '../reducers';

export const activateSocket = createAction('activate socket');
export const deActivateSocket = createAction('de activate socket');
export const fetchAllConversation = createAction('get all conversation');
export const setAllConversation = createAction<IConverstaion[]>('get all conversation');
export const fetchMessages = createAction<{ phoneNumber: string; pageNo: number }>(
    'get specific conversation messages',
);
export const setSelectedPhoneNumber = createAction<{ phoneNumber: string }>('get specific conversation messages');
export const setMessages = createAction<IMessages[]>('set specific conversation messages');
export const addMessages = createAction<IMessages>('add new message');
export const updateMessages = createAction<IMessages>('update existing message');
export const appendMessages = createAction<IMessages[]>('append new message');
export const typeMessage = createAction<string>('type message');
export const sendMessages = createAction<{ phoneNumber: string; message: string }>('send new message');
export const setHasMessages = createAction<boolean>('set has messages');
export const updateHostedNumberData = createAction<any>('update hosted number data');
export const updateHostedNumberError = createAction<any>('update hosted number error');
export const changeHostedNumberData = createAction<{ key: string; value: string}>('change hosted number data');
export const toggleHostedNumberLoader = createAction<boolean>('set hosted number data loader');
export const getHostedNumberData = createAction('get hosted number data');
export const setHostedNumberData = createAction('get hosted number data');
export const uploadImage = createAction<any>('to upload images');
export const setMMSURLIds = createAction<IMMSUrls[]>('mms url ids');
export const toggleChatLoader = createAction<boolean>('chat loader toggle');
export const setNotificationEnable = createAction<boolean>('set notification enable');
export const setFcmToken = createAction<string>('set fcm token');
