import { IStoreState } from '../types';

export const getTemplates = (state: IStoreState) => state.messagetools.templates.data;
export const getTemplatesCurrentPage = (state: IStoreState) => state.messagetools.templates.currentPage;
export const getTemplatesPerPage = (state: IStoreState) => state.messagetools.templates.perPage;
export const getNewTemplatesFormData = (state: IStoreState) => state.messagetools.templates.newTemplate;
export const getNewTemplatesFormErrors = (state: IStoreState) => state.messagetools.templates.newTemplateErrors;
export const getTotalTemplatesRecords = (state: IStoreState) => state.messagetools.templates.totalRecords;

export const getBroadcasts = (state: IStoreState) => state.messagetools.broadcast.data;
export const getBroadcastCurrentPage = (state: IStoreState) => state.messagetools.broadcast.currentPage;
export const getBroadcastPerPage = (state: IStoreState) => state.messagetools.broadcast.perPage;
export const getNewBroadcastFormData = (state: IStoreState) => state.messagetools.broadcast.newBroadcast || {};
export const getNewBroadcastFormErrors = (state: IStoreState) => state.messagetools.broadcast.newBroadcastErrors;
export const getTotalBroadcastRecords = (state: IStoreState) => state.messagetools.broadcast.totalRecords;
export const isBroadcastLoaderLoding = (state: IStoreState) => state.messagetools.broadcast.isLoading;

export const getResponder = (state: IStoreState) => state.messagetools?.responder?.data || [];
export const getResponderCurrentPage = (state: IStoreState) => state.messagetools?.responder?.currentPage || 1;
export const getResponderPerPage = (state: IStoreState) => state.messagetools?.responder?.perPage || 10;
export const getNewResponderFormData = (state: IStoreState) => state.messagetools?.responder?.newResponder || {};
export const getNewResponderFormErrors = (state: IStoreState) => state.messagetools?.responder?.newResponderErrors;
export const getTotalResponderRecords = (state: IStoreState) => state.messagetools?.responder?.totalRecords || 0;
export const getResponderType = (state: IStoreState) => state.messagetools?.responder?.type || 'keyword';
