import { createReducer } from 'redux-act';
import {
    changeMicroUrlsListCurrentPage,
    changeMicroUrlsListPerPage,
    changeMicroUrlData,
    createNewMicroUrlData,
    resetNewMicroUrlData,
    setNewMicroUrlData,
    setNewMicroUrlErrorValue,
    setTotalMicroUrlsRecords,
    signOutUser,
    setMicroUrlList,
    setReferralList,
    changeReferralListCurrentPage,
    changeReferralListPerPage,
    setTotalReferralRecords,
    changeNewReferralData,
    setNewReferralData,
    setNewReferralErrorValue,
    resetNewReferralData,
    changeReferralMessage,
} from '../actions';
export interface IMicroUrl {
    id: number;
    event_name: string;
    short_url: string;
    long_url: string;
    is_referral: boolean;
    unique_event_count: number;
    claims?: number;
    total_event_count: number;
}
export interface IReferral {
    id: number;
    first_name: string;
    last_name: string;
    phone_number: string;
    referral_first_name: string;
    referral_last_name: string;
    created_at: string;
}
export interface IAnalyticsReducer {
    microUrls: IMicroUrl[];
    referralData: IReferral[];
    newReferralData: any;
    newReferralMessage: any;
    newReferralDataError: any;
    newMicroUrlData: any;
    newMicroUrlDataError: any;
    isLoading: boolean;
    totalRecords: number;
    perPage: number;
    currentPage: number;
    referralCurrentPage: number;
    referralPerPage: number;
    referralTotalRecords: number;
}

const initialState: IAnalyticsReducer = {
    microUrls: [],
    referralData: [],
    newReferralData: {},
    newReferralMessage: undefined,
    newReferralDataError: {},
    newMicroUrlData: {},
    newMicroUrlDataError: {},
    currentPage: 1,
    perPage: 10,
    totalRecords: 0,
    referralCurrentPage: 1,
    referralPerPage: 10,
    referralTotalRecords: 0,
    isLoading: false,
};

export const analytics = createReducer<IAnalyticsReducer>({}, initialState);

analytics.on(changeMicroUrlData, (state: IAnalyticsReducer, payload: { key: string; value: string }) => ({
    ...state,
    newMicroUrlData: {
        ...state.newMicroUrlData,
        [payload.key]: payload.value,
    },
    newMicroUrlDataError: {
        ...state.newMicroUrlDataError,
        [payload.key]: '',
    },
}));
analytics.on(changeNewReferralData, (state: IAnalyticsReducer, payload: { key: string; value: string }) => ({
    ...state,
    newReferralData: {
        ...state.newReferralData,
        [payload.key]: payload.value,
    },
    newReferralDataError: {
        ...state.newReferralDataError,
        [payload.key]: '',
    },
}));
analytics.on(setNewReferralData, (state: IAnalyticsReducer, payload: any) => ({
    ...state,
    newReferralData: payload,
}));
analytics.on(setNewReferralErrorValue, (state: IAnalyticsReducer, payload: any) => ({
    ...state,
    newReferralDataError: payload,
}));
analytics.on(resetNewReferralData, (state: IAnalyticsReducer) => ({
    ...state,
    newReferralData: {},
    newReferralDataError: {},
}));
analytics.on(setNewMicroUrlData, (state: IAnalyticsReducer, payload: any) => ({
    ...state,
    newMicroUrlData: payload,
}));
analytics.on(setNewMicroUrlErrorValue, (state: IAnalyticsReducer, payload: any) => ({
    ...state,
    newMicroUrlDataError: payload,
}));
analytics.on(createNewMicroUrlData, (state: IAnalyticsReducer) => ({
    ...state,
    isLoading: true,
}));
analytics.on(resetNewMicroUrlData, (state: IAnalyticsReducer) => ({
    ...state,
    newMicroUrlData: {},
}));
analytics.on(setMicroUrlList, (state: IAnalyticsReducer, payload: any[]) => ({
    ...state,
    microUrls: payload,
}));
analytics.on(setReferralList, (state: IAnalyticsReducer, payload: any[]) => ({
    ...state,
    referralData: payload,
}));
analytics.on(changeMicroUrlsListCurrentPage, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    currentPage: payload,
}));
analytics.on(changeMicroUrlsListPerPage, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    perPage: payload,
}));
analytics.on(setTotalMicroUrlsRecords, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    totalRecords: payload,
}));
analytics.on(changeReferralListCurrentPage, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    referralCurrentPage: payload,
}));
analytics.on(changeReferralListPerPage, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    referralPerPage: payload,
}));
analytics.on(setTotalReferralRecords, (state: IAnalyticsReducer, payload: number) => ({
    ...state,
    referralTotalRecords: payload,
}));
analytics.on(changeReferralMessage, (state: IAnalyticsReducer, payload: string) => ({
    ...state,
    newReferralMessage: payload,
}));
analytics.on(signOutUser, () => initialState);
