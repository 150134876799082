import React from 'react';

const MessagingToolsConfig = {
    routes: [
        {
            path: '/message-tools/templates',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./templates/TemplateListPage')),
        },
        {
            path: '/message-tools/broadcast',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./broadcast/BroadcastListPage')),
        },
        {
            path: '/message-tools/broadcast/new',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./broadcast/NewBroadcastPage')),
        },
        {
            path: '/message-tools/broadcast/:id',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./broadcast/NewBroadcastPage')),
        },
        {
            path: '/message-tools/responder',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./responder/ResponderPage')),
        },
    ],
};
export default MessagingToolsConfig;
