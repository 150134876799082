import { IStoreState } from "../types";

export const getIsUserAuth = (state: IStoreState) =>
    state.user.isAuth;

export const getRegistrationResponse = (state: IStoreState) =>
    state.user.registerMessage;
export const getPreDraftMessage = (state: IStoreState) =>
    state.user.pre_draft_message;

export const getCurrentUser = (state: IStoreState) =>
    state.user.user;

export const getRoutes = (state: IStoreState) =>
    state.user.routes || [];

export const getComponentRoutes = (state: IStoreState) =>
    state.user.component_routes || [];

export const getUserLoader = (state: IStoreState) =>
    !!state.user.isLoading;
export const getNewSubUserFormErrors = (state: IStoreState) =>
    state.user.subUser?.newSubUserErrors;

export const getNewUserFormData = (state: IStoreState) =>
    state.user.subUser?.newSubUser;

export const getNewUserCurrentPage = (state: IStoreState) =>
    state.user.subUser?.currentPage || 1;


export const getNewUserPerPage = (state: IStoreState) =>
    state.user.subUser?.perPage || 10;

export const getSubuser = (state: IStoreState) =>
    state.user?.subUser?.subUsers || [];


export const getTotalSubUserRecords = (state: IStoreState) =>
    state.user.subUser?.totalRecords || 0;
