import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useDetectMobileView from '../../modules/hooks/useDetectMobileView';
import { toggleSideMenu } from '../../modules/actions';
import { useDispatch } from 'react-redux';

interface ILevelMenuLinks {
    partialLink: string;
    parentLinkText: string;
    icon: string;
    links: {
        navigateTo: string;
        text: string;
    }[];
}
const LevelMenuLinks: React.FC<ILevelMenuLinks> = ({ parentLinkText, links, partialLink, icon }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const isMobile = useDetectMobileView();
    const dropDown = useRef(null);
    const toggleItem = useCallback(() => {
        setOpen((oldState) => !oldState);
    }, []);
    const itemOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const itemClose = useCallback(() => {
        setOpen(false);
    }, []);
    const toggleSideMenuCallback = useCallback(() => {
        setOpen(false);
        dispatch(toggleSideMenu());
    }, [dispatch]);

    return (
        <li
            onMouseEnter={!isMobile ? itemOpen : () => {}}
            onMouseLeave={!isMobile ? itemClose : () => {}}
            className={`${classnames({
                open: open,
                active: location.pathname.indexOf(partialLink) !== -1,
            })} menu-item`}
        >
            <a
                href="javascript:void(0)"
                className="menu-link menu-toggle"
                ref={dropDown}
                onClick={isMobile ? toggleItem : () => {}}
            >
                <i className={`menu-icon tf-icons ti ti-${icon}`} />
                <div data-i18n="Layouts">{parentLinkText}</div>
            </a>

            <ul className="menu-sub">
                {links.map((x, index) => {
                    return (
                        <li
                            key={`menu_key_${index}`}
                            className={`${classnames({
                                active: location.pathname === x.navigateTo,
                            })} menu-item`}
                        >
                            <Link to={x.navigateTo} onClick={toggleSideMenuCallback} className="menu-link">
                                <div data-i18n="List">{x.text}</div>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
};
export default LevelMenuLinks;
