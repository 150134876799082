import { IStoreState } from '../types';

export const getStripeCardDetails = (state: IStoreState) => state.stripe.cardDetails;

export const getAllPlans = (state: IStoreState) => state.stripe.allPlans || [];

export const getLastInvoice = (state: IStoreState) => state.stripe.lastInvoice;

export const getCredits = (state: IStoreState) => state.stripe.credits;

export const getStripeLoading = (state: IStoreState) => state.stripe.isLoading;
