import axios from "axios";
import { APIUrl } from "../apiConstants";

export const getBasicInformationAPI = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.isvBasicInformation}`, {
        headers,
        timeout: 10000,
    });
};

export const setBasicInformationAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.isvBasicInformation}`, payload, {
        headers,
        timeout: 10000,
    });
};

export const getCampaignInformationAPI = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.isvCampaignInformation}`, {
        headers,
        timeout: 10000,
    });
};

export const getTollfreeInformationAPI = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.isvTollfreeInformation}`, {
        headers,
        timeout: 10000,
    });
};


export const setCampaignInformationAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.isvCampaignInformation}`, payload, {
        headers,
        timeout: 10000,
    });
};


export const setTollfreeInformationAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.isvTollfreeInformation}`, payload, {
        headers,
        timeout: 10000,
    });
};
