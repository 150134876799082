import { SagaIterator } from 'redux-saga';
import * as actions from '../actions';
import {
    createNewReferralAPI, createPreDraftMessageAPI,
    createReferralUrlAPI,
    createUpdateMicroUrlAPI,
    deleteMicroUrlAPI,
    getMicroUrlAPI, getPreDraftMessageAPI,
    getReferralUrlAPI, redeemReferralAPI
} from '../utils/Apis/analytics';
import { showAlert } from '../utils/AlertUtils';
import { put, select, call } from 'redux-saga/effects';
import {
    getMicroUrlsCurrentPage,
    getMicroUrlsPerPage,
    getNewMicroUrlData, getNewReferralData,
    getReferralCurrentPage,
    getReferralPerPage
} from '../selectors';
import { ISagaAction } from "../types";

export const createNewMicroUrl = function* (): SagaIterator {
    try {
        const newMicroUrlData = yield select(getNewMicroUrlData);
        const { id, long_url, event_name = '' } = newMicroUrlData;
        const responseData = yield call(createUpdateMicroUrlAPI, {
            id,
            long_url,
            event_name,
        });
        yield put(actions.setNewMicroUrlData(responseData.data.data));
        const data = {
            title: 'Success!',
            text: `URL shortener successfully ${id ? 'updated' : 'created'}.`,
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewMicroUrlErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};
export const getAllMicroUrls = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const payload = action.payload;
        const page = yield select(getMicroUrlsCurrentPage);
        const limit = yield select(getMicroUrlsPerPage);
        const result = yield call(getMicroUrlAPI, { page, limit, ...payload });
        yield put(actions.setMicroUrlList(result.data.data.rows));
        yield put(actions.setTotalMicroUrlsRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};

export const getAllReferral = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const payload = action.payload;
        const page = yield select(getReferralCurrentPage);
        const limit = yield select(getReferralPerPage);
        const result = yield call(getReferralUrlAPI, { page, limit, id: payload });
        yield put(actions.setReferralList(result.data.data.rows));
        yield put(actions.setTotalReferralRecords(result.data.data.count));
    } catch (e) {
        console.log(e);
    }
};


export const createValidationCode = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { name, phone_number, code } = yield select(getNewReferralData);
        yield call(createReferralUrlAPI, { name, phone_number, code });
        yield put(actions.resetNewReferralData());
        const data = {
            title: 'Success!',
            text: 'Referral has successfully claimed.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
            yield put(actions.setNewReferralErrorValue(e?.response?.data?.errors));
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};


export const createReferral = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { message, address_ids } = action.payload;
        if (message.indexOf("{{contact_name}}") === -1) {
            return;
        }
        if (message.indexOf("{{unique_contact_mini_url}}") === -1) {
            return;
        }
        yield call(createNewReferralAPI, { message, address_ids });
        const data = {
            title: 'Success!',
            text: 'Referral sent successfully.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};

export const redeemReferral = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const id = action.payload;
        yield call(redeemReferralAPI, id);
        const data = {
            title: 'Success!',
            text: 'Referral redeemed successfully.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        yield put(actions.fetchReferralData(parseInt(window.location.pathname.replace("/referrals/lookup/", ""))));
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};
export const createPreDraftMessage = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { message, referral_message } = action.payload;
        yield call(createPreDraftMessageAPI, { message, referral_message });
        const data = {
            title: 'Success!',
            text: 'Pre Draft message saved successfully.',
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield put(actions.togglePredraftMessageModal(false));
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};
export const getPreDraftMessage = function* (): SagaIterator {
    try {
        const data = yield call(getPreDraftMessageAPI);
        yield put(actions.updatePreDraftMessage(data.data.data.pre_draft_message));
        yield put(actions.changeReferralMessage(data.data.data.referral_message || ''));
    } catch (e: any) {
        console.log(e);
        if (typeof e?.response?.data?.errors === 'object') {
        } else {
            if (e !== 'Session expired') {
                const data = {
                    title: 'Error!',
                    text: e?.response?.data?.error || e?.response?.data?.message || '',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                };
                yield call(showAlert, data);
            }
        }
    }
};

export const deleteMicroUrl = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const data = {
            title: 'Caution!',
            text: 'Are you sure you want to delete this?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        const alertData = yield call(showAlert, data);
        if (alertData.isConfirmed) {
            const id = action.payload;
            const result = yield call(deleteMicroUrlAPI, {id});
            yield put(actions.setMicroUrlList(result.data.data.rows));
            yield put(actions.setTotalMicroUrlsRecords(result.data.data.count));
        }
    } catch (e) {
        console.log(e);
    }
};
