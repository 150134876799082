import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest, fork } from 'redux-saga/effects';
import * as actions from '../actions';
import * as user from './user';
import * as addresses from './addresses';
import * as messageTools from './message-tools';
import * as twilioNumbers from './buy.number';
import * as messages from './messages';
import * as analytics from './analytics';
import * as isv from './isv';
import * as stripe from './stripe';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
    yield takeLatest(actions.signInUser, user.signIn);
    yield takeLatest(actions.signUpUser, user.signUp);
    yield takeLatest(actions.fetchProfile, user.getProfile);
    yield takeLatest(actions.getRoutes, user.getRoutes);
    yield takeLatest(actions.resetPassword, user.resetPasswordSaga);
    yield takeLatest(actions.forgotPassword, user.forgotPasswordSaga);
    yield takeLatest(actions.verifyMyAccount, user.verifyAccountSaga);
    yield takeLatest(actions.updateProfile, user.updateProfile);
    yield takeLatest(actions.userAutoLogin, user.userAutoLogin);
    yield takeLatest(actions.fetchSubUsers, user.getAllSubuser);
    yield takeLatest(actions.createNewSubuser, user.createNewSubuser);

    yield takeLatest(actions.fetchAddress, addresses.getAllAddress);
    yield takeLatest(actions.createNewAddress, addresses.createNewAddress);
    yield takeLatest(actions.changeAddressStatus, addresses.changeStatus);
    yield takeLatest(actions.importAddress, addresses.importAddresses);
    yield takeLatest(actions.fetchExistingTags, addresses.getAllTagsSaga);

    yield takeLatest(actions.fetchTemplates, messageTools.getAllTemplates);
    yield takeLatest(actions.createNewTemplates, messageTools.createNewTemplate);
    yield takeLatest(actions.updateTemplate, messageTools.updateTemplate);
    yield takeLatest(actions.deleteTemplate, messageTools.deleteTemplate);

    yield takeLatest(actions.fetchBroadcast, messageTools.getAllBroadcast);
    yield takeLatest(actions.createNewBroadcast, messageTools.createNewBroadcast);
    yield takeLatest(actions.updateBroadcast, messageTools.updateBroadcast);
    yield takeLatest(actions.deleteBroadcast, messageTools.deleteBroadcast);


    yield takeLatest(actions.fetchResponder, messageTools.getAllResponder);
    yield takeLatest(actions.createNewResponder, messageTools.createNewResponder);
    yield takeLatest(actions.updateResponder, messageTools.updateResponder);
    yield takeLatest(actions.deleteResponder, messageTools.deleteResponder);

    yield takeLatest(actions.fetchNumbers, twilioNumbers.getAllAvailableNumbers);
    yield takeLatest(actions.buyNumber, twilioNumbers.buyNewNumber);

    yield takeLatest(actions.fetchAllConversation, messages.fetchAllConversation);
    yield takeLatest(actions.fetchMessages, messages.fetchAllMessage);
    yield takeLatest(actions.deActivateSocket, messages.doDeActivateSocket);
    yield takeLatest(actions.signOutUser, messages.doDeActivateSocket);
    // yield takeEvery(actions.activateSocket, messages.onActivateSocket);
    yield takeEvery(actions.sendMessages, messages.sendNewMessage);
    yield takeEvery(actions.getHostedNumberData, messages.getHostedNumber);
    yield takeEvery(actions.setHostedNumberData, messages.setHostedNumber);
    yield takeEvery(actions.uploadImage, messages.uploadImage);
    yield fork(messages.messageFetchSaga);
    yield fork(messages.converstationFetchSaga);
    yield fork(messages.newMessageFetchSaga);
    yield fork(messages.updateMessageFetchSaga);
    yield fork(messages.errorMessageFetchSaga);
    yield fork(messages.notificationMessageService);

    yield takeEvery(actions.createNewMicroUrlData, analytics.createNewMicroUrl);
    yield takeEvery(actions.fetchMicroUrlData, analytics.getAllMicroUrls);
    yield takeEvery(actions.deleteMicroUrlData, analytics.deleteMicroUrl);
    yield takeEvery(actions.createNewReferralData, analytics.createValidationCode);
    yield takeEvery(actions.fetchReferralData, analytics.getAllReferral);
    yield takeEvery(actions.fetchPreDraftMessage, analytics.getPreDraftMessage);
    yield takeEvery(actions.savePreDraftMessage, analytics.createPreDraftMessage);
    yield takeEvery(actions.sendReferralMessage, analytics.createReferral);
    yield takeEvery(actions.redeemReferral, analytics.redeemReferral);

    yield takeEvery(actions.updateBasicInformation, isv.updateBasicInformationDataSaga);
    yield takeEvery(actions.updateCampaignInformation, isv.updateCampaignInformationDataSaga);
    yield takeEvery(actions.updateTollfreeNumberInformation, isv.updateTollfreeInformationDataSaga);
    yield takeEvery(actions.fetchBasicInformation, isv.getBasicInformationData);
    yield takeEvery(actions.fetchCampaignInformation, isv.getCampaignInformationData);
    yield takeEvery(actions.fetchTollfreeInformation, isv.getTollfreeInformationData);


    yield takeLatest(actions.setCardDetails, stripe.storeNewCard);
    yield takeLatest(actions.getCardDetails, stripe.getNewCardAPI);
    yield takeEvery(actions.getCredits, stripe.getCreditsAPI);
    yield takeEvery(actions.getAllPlans, stripe.getAllPlansAPI);
    yield takeEvery(actions.updatePlan, stripe.updatePlanAPI);
    yield takeEvery(actions.getLastInvoiceAction, stripe.getLastInvoiceAPI);
    yield takeEvery(actions.addCredits, stripe.addCreditsAPI);
}
