import axios from 'axios';
import { APIUrl } from '../apiConstants';
import { requestForToken } from '../../../firebase';

export const getAvailableNewNumber = (payload: string) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${APIUrl.buyNumbers}/?contains=${payload}`, {
        headers,
        timeout: 10000,
    });
};

export const buyNewNumbersAPI = (phoneNumber: string) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(
        `${APIUrl.buyNumbers}/buy-now`,
        { phoneNumber },
        {
            headers,
            timeout: 10000,
        },
    );
};

export const getHostedNumberAPI = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(APIUrl.hostedNumber, {
        headers,
        timeout: 10000,
    });
};

export const setHostedNumberAPI = (data: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(APIUrl.hostedNumber, data, {
        headers,
        timeout: 10000,
    });
};

export const uploadFileAPI = (data: any) => {
    const headers = {
        'Content-Type': 'multipart/form-data;',
    };
    const formData = new FormData();
    for (const f of data) {
        formData.append('file', f);
    }
    return axios.post(APIUrl.mmsUpload, formData, {
        headers,
        timeout: 10000,
    });
};

export const addFCMToken = (payload: any) => {
    const emailheader = {};
    return axios.post(APIUrl.registerDevice, payload, {
        headers: emailheader,
    });
};

export const requestUserPermission = () => {
    return Notification.requestPermission();
};

export const getToken = () => {
    return requestForToken();
};
export const getDeviceId = () => {
    const length = 50;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};
/*

export const onNotificationMessage = () => {
    return eventChannel((emitter: any) => {
        messaging()
            .getInitialNotification()
            .then((remoteMessage) => {
                emitter(remoteMessage);
            });
        messaging().onMessage((remoteMessage) => {
            emitter(remoteMessage);
        });
        messaging().onNotificationOpenedApp((remoteMessage) => {
            emitter(remoteMessage);
        });
        return () => {
            emitter(END);
        };
    });
};
*/
