import { createAction } from 'redux-act';
import { ITopBarNavigation, IUser } from '../reducers';

export const signInUser = createAction<{ email: string; password: string }>('to signin');
export const signUpUser =
    createAction<{
        first_name: string;
        last_name: string;
        email: string;
        center_name: string;
        phone_number: string;
        password: string;
        navigation: any;
    }>('to sign up');
export const signOutUser = createAction('to sign out');
export const authUser = createAction('authorize user');
export const setSignupMessage = createAction<{ isError: boolean; message: string; errors?: object } | undefined>(
    'set signup response',
);
export const setUserData = createAction<IUser>('set user response');
export const updatePreDraftMessage = createAction<string>('update user predraft message');
export const setRoutes = createAction<ITopBarNavigation[]>('update routes');
export const getRoutes = createAction('get routes');
export const setComponentRoutes = createAction<string[]>('set component routes');
export const fetchProfile = createAction('fetch profile details');
export const forgotPassword = createAction<{ email: string }>();
export const resetPassword = createAction<{ newPassword: string; code: string; navigation: any }>();
export const verifyMyAccount = createAction<{ code: string; navigation: any }>();
export const updateProfile =
    createAction<{ firstName: string; lastName: string; centerName: string; website: string;  businessPhoneNumber: string; location: string }>();
export const userAutoLogin = createAction<{ code: string; navigation: any }>();

export const setSubUsers =
    createAction<IUser[]>('set sub users');
export const fetchSubUsers =
    createAction<{ filterBy?: string | null; searchText?: string }>('get sub users');
export const setTotalSubUserRecords = createAction<number>('set total sub users records ');
export const changeSubuserCurrentPage = createAction<number>('set sub users current page');
export const changeSubuserPerPage = createAction<number>('set sub users per page');
export const changeNewUserFieldValue = createAction<any>('change sub users field value');
export const setNewSubuserErrorValue = createAction<any>('set error new subuser values');
export const setNewSubuserValue = createAction<IUser>('set new subuser values');
export const resetNewSubuserForm = createAction('new sub user reset form');
export const createNewSubuser = createAction<any>('create new sub user');
