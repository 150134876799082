import React, { useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import { Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(
    'pk_live_51N3nOdGMu8bVrcSDWW6kVuablxipQKE0EtY8w0OaeeZOERFNxlzTU72DiK7hjIslZpgleCCp6cgS2hdxRpr8YHDJ00T3Mk9KwI',
);

const Router = () => {
    const { routes } = useAppContext();
    const options = useRef({
        // passing the client secret obtained from the server
    });
    return routes ? (
        <Elements stripe={stripePromise} options={options.current}>
            <Routes>
                {routes.map((route, i) => (
                    <Route key={`route_${i}`} path={route.path} element={<route.component route={route} />} />
                ))}
            </Routes>
        </Elements>
    ) : null;
};

export default React.memo(Router);
