import { createReducer } from 'redux-act';
import {
    fetchAddress,
    signOutUser,
    mergeAllAddress,
    setAddress,
    changeAddressCurrentPage,
    changeAddressPerPage,
    changeNewAddressFieldValue,
    resetNewAddressForm,
    setNewAddressErrorValue,
    setTotalAddressRecords,
    toggleAddNewAddressModal,
    setExistingAddressTags, setNewAddressValue,
} from '../actions';

export interface Addresses {
    id?: number;
    first_name?: string;
    last_name?: string;
    phone_number: string;
    email?: string | null;
    ref_code?: string | null;
    profile_pic?: string | null;
    tag_name?: string | null;
    status: string;
    birthdate?: string | null;
    created_at?: string;
    updated_at?: string;
}

export interface IAddressesReducer {
    addresses: Addresses[];
    newAddress?: any;
    newAddressErrors?: any;
    tags: string[];
    totalRecords: number;
    perPage: number;
    currentPage: number;
    isLoading: boolean;
}

const initialState = {
    addresses: [],
    newAddress: undefined,
    newAddressErrors: undefined,
    totalRecords: 0,
    tags: [],
    currentPage: 1,
    perPage: 10,
    isLoading: false,
};

export const addresses = createReducer<IAddressesReducer>({}, initialState);

addresses.on(fetchAddress, (state: IAddressesReducer) => ({
    ...state,
    isLoading: true,
}));

addresses.on(mergeAllAddress, (state: IAddressesReducer, payload: Addresses[]) => ({
    ...state,
    addresses: [...state.addresses, ...payload],
}));

addresses.on(setAddress, (state: IAddressesReducer, payload: Addresses[]) => ({
    ...state,
    addresses: payload,
}));
addresses.on(changeAddressCurrentPage, (state: IAddressesReducer, payload: number) => ({
    ...state,
    currentPage: payload,
}));
addresses.on(changeAddressPerPage, (state: IAddressesReducer, payload: number) => ({
    ...state,
    perPage: payload,
}));
addresses.on(changeNewAddressFieldValue, (state: IAddressesReducer, payload: { key: string; value: string }) => {
    console.log(payload)
    return {
    ...state,
    newAddress: {
        ...state.newAddress,
        [payload.key]: payload.value,
    },
    newAddressErrors: {
        ...state.newAddressErrors,
        [payload.key]: '',
    },
}});
addresses.on(resetNewAddressForm, (state: IAddressesReducer) => ({
    ...state,
    newAddress: undefined,
    newAddressErrors: undefined,
}));
addresses.on(toggleAddNewAddressModal, (state: IAddressesReducer, payload: boolean) => ({
    ...state,
    newAddress: payload ? state.newAddress : undefined,
    newAddressErrors: payload ? state.newAddressErrors : undefined,
}));
addresses.on(setNewAddressErrorValue, (state: IAddressesReducer, payload: any) => ({
    ...state,
    newAddressErrors: payload,
}));

addresses.on(setNewAddressValue, (state: IAddressesReducer, payload: any) => ({
    ...state,
    newAddress: payload,
}));

addresses.on(setTotalAddressRecords, (state: IAddressesReducer, payload: number) => ({
    ...state,
    totalRecords: payload,
}));

addresses.on(setExistingAddressTags, (state: IAddressesReducer, payload: string[]) => ({
    ...state,
    tags: payload,
}));
addresses.on(signOutUser, () => initialState);
