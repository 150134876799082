import React from 'react';

const ISVFormsConfig = {
    routes: [
        {
            path: '/isv-forms',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./ISVFormScreen')),
        },
        {
            path: '/settings/isv-forms',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./ISVFormScreen')),
        },
    ],
};
export default ISVFormsConfig;
